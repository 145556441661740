:root{
	font-size: 10px;
}

*,
*:before,
*:after{
	box-sizing: border-box;
}

body{
	font-family: 'Montserrat', sans-serif;
	font-size: 2.1rem;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
}

button{
	padding: 0;
}

iframe{
	border: none;
}

a{
	text-decoration: none;
	transition: .2s linear;
	color: #000;
}

a:hover{
	opacity: .5;
}

h1, h2, h3, h4, h5, h6, p{
	margin: 0;
}

h1, h2, h3, h4, h5, h6{
	font-weight: 600;
	color: #000;
	font-size: 2.3rem;
}

.recaptcha__inner{
	margin-top: 1.5rem;
	width: 100%;
}

.bold__text{
	font-size: 2.2rem;
	color: #2d3436;
	font-weight: 600;
}

.button, .submit{
	outline: none;
	border: none;
	background: none;
	cursor: pointer;
	font-family: 'Montserrat', sans-serif;
	transition: .2s linear;
}

.button:hover, .submit:hover{
	opacity: .6;
}

.input, #comment, #author, #s{
	outline: none;
	border: none;
	font-size: 1.7rem;
	padding: .7rem 1.2rem;
	color: #000;
	font-family: 'Montserrat', sans-serif;
	resize: none;
}

.input::placeholder, #comment:placeholder, #author:placeholder,
#s::placeholder{
	color: #c7cbcf;
}

.input:focus::placeholder, #comment:focus::placeholder,
#author:focus::placeholder, #s:focus::placeholder{
	opacity: 0;
}

.title{
	font-size: 2.2rem;
	color: #2d3436;
	font-weight: 600;
	padding-left: 5rem;
	z-index: 5;
	position: relative;
	margin-top: 1rem;
}

.title:before{
	content: "";
	display: block;
	width: 60px;
	height: 100%;
	position: absolute;
	top: 0;
	left: 4px;
	background: #cdcdcd;
	z-index: -1;
	transform: skewX(-15deg);
	border-radius: 5px;
}

.mt1{
	margin-top: 1rem;
}

.mt2{
	margin-top: 2rem;
}

.mt3{
	margin-top: 3rem;
}

.mt4{
	margin-top: 4rem;
}

.container{
	width: 100%;
	margin: 0 auto;
	max-width: 1250px;
}

.tovar__card{
	transition: .2s linear;
}

.tovar__card.active, .tcmagazini__item.active, .tag__item.active,
.tcpage__item.active{
	transform: scale(0);
	position: absolute;
}

.dark h1, .dark h2, .dark h3, .dark h4, .dark h5, .dark h6{
	color: #fff;
}

// Шапка

.header{
	padding: 3rem 0;
}

.header__inner{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header__content{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.header__logo--inner{
	width: 265px;
	height: auto;
}

.header__logo{
	width: 100%;
	height: auto;
}

.header__search--inner{
	position: relative;
}

.header__search--input{
	border-radius: 50px;
	border: 2px solid #009635 ;
	width: 320px;
}

.header__search--icon--inner{
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 50px;
	border-radius: 50px;
	background: #009635 ;
	display: flex;
	align-items: center;
	justify-content: center;
}

.header__cart--inner{
	display: flex;
	align-items: center;
	position: relative;
}

.header__cart--content{
	width: 35px;
	height: 35px;
	border-radius: 50%;
	background: #009635 ;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 2rem;
}

.header__city--inner, .header__city--inner--mobile{
	padding: .75rem 1.5rem;
	background: #009635 ;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.5rem;
	border-radius: 50px;
}

.header__city--inner--mobile{
	margin-top: 2rem;
}

.header__city--img{
	margin-right: .5rem;
}

.header__city--value{
	color: #fff;
	font-size: 1.6rem;
	text-decoration: underline;
	font-weight: 300;
}

.header__nav{
	width: 100%;
	display: flex;
	align-items: center;
	margin-top: 3rem;
	flex-wrap: wrap;
}

.header__nav--item{
	color: #34495e;
	font-size: 2rem;
	transition: .2s linear;
	padding: .7rem 1rem;
	margin: 1rem 2rem 0 0;
	border-radius: 4px;
	transform: skewX(-10deg);
}

.header__nav--item.active{
	color: #fff;
	background: #009635 ;
}

.header__nav--item:last-child{
	margin-right: 0;
}

.header__nav--item:hover{
	color: #ffff;
	background: #009635 ;
}

.modal__city{
	padding: 7rem 1.5rem 2rem 1.5rem;
	border-radius: 7px;
	position: absolute;
	top: 125%;
	right: 0;
	width: 470px;
	z-index: 15;
	background: #efefef;
	display: none;
	flex-direction: column;
	align-items: flex-start;
}

.modal__city.active{
	display: flex;
}

.modal__city--cross{
	position: absolute;
	top: 0;
	right: 0;
	padding: .9rem;
	background: #34495e;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0 7px 0 7px;
}

.modal__city--input--inner{
	width: 100%;
	position: relative;
}

.tag__input--inner{
	width: 100%;
}

.tag__input--inner input#s{
	border: 1px solid #9b9e9f!important;
	width: 100%!important;
	background: none!important;
	border-radius: 7px!important;
	color: #84878a!important;
	padding: .5rem 1.2rem!important;
	font-size: 1.7rem!important;
}

.tag__input--inner input#searchsubmit{
	display: none!important;
}

.modal__city--input{
	width: 100%;
	background: none;
	border: 1px solid #d4d4d4;
	border-radius: 7px;
	color: #84878a;
}

.modal__city--names--inner{
	width: 100%;
	margin-top: 1.5rem;
	display: flex;
	align-items: flex-start;
}

.modal__city--search--icon{
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
}

.modal__city--names--item{
	width: 46%;
	margin-right: 2rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.modal__city--names--item:last-child{
	margin-right: 0;
}

.modal__city--name{
	color: #2d3436;
	font-size: 1.9rem;
	font-weight: 300;
}

.modal__city--name.active{
	font-weight: 600;
}

// Главный блок

.main{
	padding: 17rem 0rem 15rem 0;
	background: url("../img/main-bg.jpg") center no-repeat;
	background-size: cover;
}

.main__inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	text-align: end;
}

.main__title{
	font-size: 9.8rem;
	color: #2c3e50;
}

.main__border{
	font-size: 6.3rem;
	color: #fff;
	padding: .6rem 1.5rem 1rem 1.5rem;
	background: #009635 ;
	border-radius: 10px;
	font-weight: 600;
	line-height: 1;
}

.main__subtitle{
	font-size: 5.1rem;
	color: #2c3e50;
}

// Торговые центры

.shoping, .city{
	padding: 3.5rem 0;
}

.shoping__inner, .city__inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.shoping__inner .shoping__content{
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	flex-direction: row;
}

.tcgorod__content--item--address--button span{
	padding: 0;
}

.shoping__item{
	background: #f7f7f7;
	border-radius: 3px;
	padding: 1rem 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 23%;
	margin: 2rem 2rem 0 0;
	transition: .2s linear;
}

.shoping__item:hover{
	background: #e2e2e2;
}

.shoping__item--img, .tovar__item--img{
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 160px;
}

.address__clock--text{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.shoping__item--img--inner, .shoping__item--img--inner--tc{
	width: 100%;
	height: 160px;
	overflow: hidden;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
}

.shoping__item--img--inner--tc{
	margin-right: 6rem;
}

.shoping__item--img--inner--tc:before{
	content: "";
	display: block;
	height: 50px;
	width: 2px;
	background: #d63031;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: -30px;
}

.shoping__name{
	text-transform: uppercase;
	text-align: center;
	margin-top: 1.5rem;
	font-weight: 300;
	font-size: 1.5rem;
	color: #2d3436;
}

.shoping__show--button{
	cursor: pointer;
}

// Отзывы

.reviews{
	padding: 2rem 0rem 9rem 0;
	background: url("../img/reviews-bg.jpg") center no-repeat;
	background-size: cover;
}

#email-notes{
	margin-top: 1rem;
	font-size: 2rem;
	font-weight: 600;
	color: #fff;
}

.reviews__inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.dark .shoping__item--img, .dark .tovar__item--img{
	border-radius: 15px;
}

.reviews__title, .comment-reply-title{
	font-size: 2.6rem;
	color: #fff;
	font-weight: 600;
	position: relative;
	padding-left: 5rem;
	z-index: 5;
}

.reviews__title:before, .comment-reply-title:before{
	content: "";
	display: block;
	width: 60px;
	height: 100%;
	position: absolute;
	top: 0;
	left: 4px;
	z-index: -1;
	background: #3CA01F;
	transform: skewX(-15deg);
	border-radius: 5px;
}

.carousel__inner{
	width: 100%;
	margin-top: 4rem;
}

.carousel__item{
	width: 100%;
	max-width: 45%;
	display: flex!important;
	margin: 0 5rem;
}

.carousel__item--left{
	width: 100%;
	max-width: 130px;
	background: #f6f6f6;
	border-radius: 7px 0 0 7px;
	padding: .5rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.carousel__item--right{
	width: 100%;
	padding: 1rem .5rem;
	background: #fff;
	border-radius: 0px 10px 10px 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.carousel__item--name--inner{
	width: 100%;
	display: flex;
	align-items: center;
}

.carousel__item--name{
	margin-right: 1rem;
	font-weight: 600;
	font-size: 2.1rem;
	color: #2d3436;
}

.carousel__item--rate--inner{
	width: 107px;
	height: auto;
}

.carousel__item--rate{
	width: 100%;
	height: auto;
}

.carousel__item--date{
	color: #2d3436;
	font-size: 1.4rem;
	font-weight: 300;
}

.carousel__item--text{
	margin-top: .5rem;
	color: #2d3436;
	font-size: 1.3rem;
}

.carousel__arrow{
	color: #fff;
	font-size: 3.5rem;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 10;
}

.carousel__arrow--left{
	left: 0;
}

.carousel__arrow--right{
	right: 0;
}

.presentation{
	margin: .5rem .5rem 0rem 0;
}

.cards__inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.slick-dots{
	margin: 0;
	position: relative;
	top: auto;
	right: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 1rem;
}

.slick-dots li{
	list-style-type: none;
	width: 12px;
	height: 12px;
	border: 2px solid #fff;
	border-radius: 50%;
	background: #fff;
	margin-right: .5rem;
	cursor: pointer;
}

.slick-dots li.slick-active{
	background: none;
}

.slick-dots li button{
	display: none;
}

#activ{
	align-items: center;
	flex-direction: row;
}

// Выбор города

.city__content{
	width: 100%;
	margin-top: 3rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.city__map--inner{
	width: 60%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.city__box{
	width: 35%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.city__title{
	color: #2d3436;
	font-size: 3.1rem;
	font-weight: 600;
}

.city__name{
	font-size: 2.1rem;
	color: #2d3436;
	font-weight: 300;
	cursor: pointer;
}

.city__active{
	font-weight: 600;
	position: relative;
}

.city__active:before{
	content: "";
	display: block;
	width: 4px;
	height: 4px;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: -8px;
	background: #fd1a1e;
}

.city__subinput{
	color: #9ba3ab;
	font-size: 1.5rem;
	font-weight: 300;
	margin-top: .5rem;
}

.city__input--inner{
	position: relative;
	margin-top: 4rem;
}

.city__input--inner:before{
	content: "Ваш город";
	display: block;
	font-size: 1.4rem;
	color: #3C7F1D;
	padding: .1rem;
	background: #fff;
	position: absolute;
	top: -9px;
	left: 16px;
	font-family: "Montserrat", sans-serif;
}

.city__input{
	border: 2px solid #3C7F1D;
	border-radius: 50px;
	padding: .7rem 1.5rem;
}


// Все магазины

.all{
	padding: 4rem 0;
}

.all__inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.all__content{
	width: 100%;
	display: flex;
	align-items: flex-start;
}

.all__filter--inner{
	display: flex;
	align-items: flex-start;
	width: 100%;
	flex-wrap: wrap;
	background: #f7f7f7;
	border-radius: 7px;
	padding: 1rem 1.4rem;
	margin-top: 2rem;
	position: sticky;
	top: 15px;
	z-index: 5;
}

.all__shop--inner{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.all__item{
	padding: 1rem 3rem;
	width: 22%;
	max-width: 22%;
	margin: 2rem 2rem 0rem 0;
	transition: .2s linear;
	background: #f7f7f7;
	border-radius: 3px;
	padding: 1rem 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}


.all__filter--title{
	font-size: 1.7rem;
	font-weight: 300;
	color: #fff;
	padding: 1rem;
	width: 100%;
	text-align: center;
	background: #009635 ;
	transform: skewX(-10deg);
	border-radius: 4px;
}

.all__filter--button{
	color: #2d3436;
	font-size: 1.75rem;
	font-weight: 600;
	margin: 1rem 1rem 0 0;
}

.carousel__item--left--img--inner{
	width: 106px;
	height: auto;
}

.carousel__item--left--img{
	width: 100%;
	height: auto;
}

.all__filter--view{
	margin: 1rem 1rem 0 0;
	font-size: 1.75rem;
	font-weight: 300;
	color: #9fa1a1;
	transition: .2s linear;
}

.all__filter--view:hover{
	color: #000;
}

// Добавить отзыв о магазине

.add__reviews{
	padding: 3rem 0rem 5rem 0;
	background: url("../img/add-review-bg.jpg") center no-repeat;
	background-size: cover;
}

.add__reviews--inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.add__reviews--box{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 30%;
}

.add__reviews--content, .comment-form{
	padding: 3rem 2rem;
	background: #fff;
	border-radius: 15px;
	margin-top: 4rem;
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	max-width: 900px;
}

.comment-notes{
	display: none;
}

.comment-form{
	flex-direction: row-reverse;
	align-items: stretch;
}

.add__reviews--comment, .comment-form-comment{
	position: relative;
}

.add__reviews--comment:before, .comment-form-comment:before{
	content: "Комментарий";
	display: block;
	font-size: 1.4rem;
	color: #3C7F1D;
	padding: .1rem;
	background: #fff;
	position: absolute;
	top: -9px;
	left: 16px;
	font-family: "Montserrat", sans-serif;
}

.comment-form-comment label{
	display: none;
}

.add__reviews--comment--form, #comment{
	width: 550px;
	height: 175px;
	resize: none;
	border-radius: 15px;
	border: 2px solid #3CA01F;
	padding: 1.2rem 1.6rem;
}

#comment{
	height: 100%;
}

.add__reviews--button, .submit{
	font-size: 1.7rem;
	color: #fff;
	background: #009635 ;
	border-radius: 50px;
	padding: 1rem 3rem;
	width: 100%;
	text-align: center;
	margin-top: 1.5rem;
}

.add__reviews--input--inner, .add__reviews--rate--inner,
.comment-form-author, .comments-rating,
.spamblock{
	width: 100%;
	position: relative;
}

.spamblock{
	padding: .5rem 1.9rem;
	border: 2px solid #3C7F1D;
	border-radius: 14px;
	background: #fff;
}

.add__reviews--rate--inner, .comments-rating{
	margin-top: 1.5rem;
}

.add__reviews--input--inner:before, .comment-form-author:before{
	content: "Ваше имя";
	display: block;
	font-size: 1.4rem;
	color: #3C7F1D;
	padding: .1rem;
	background: #fff;
	position: absolute;
	top: -9px;
	left: 16px;
	font-family: "Montserrat", sans-serif;
}

.add__reviews--rate--inner:before, .comments-rating:before,
.spamblock:before{
	content: "Оцените магазин";
	display: block;
	font-size: 1.4rem;
	color: #3C7F1D;
	padding: .1rem;
	background: #fff;
	position: absolute;
	top: -9px;
	left: 16px;
	font-family: "Montserrat", sans-serif;
}

.spamblock:before{
	content: "Защита от спама";
}

.comments-rating:before{
	left: 30px;
}

.add__reviews--input, #author{
	width: 100%;
	border: 2px solid #009635 ;
	border-radius: 50px;
}

.add__reviews--rate, .rating-container{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: .8rem 1rem;
	border: 2px solid #009635 ;
	border-radius: 50px;
}

fieldset{
	border: none;
}

.add__reviews--rate--img{
	margin-right: .5rem;
}

.add__reviews--rate--img:last-child{
	margin-right: 0;
}

// Хлебные крошки

.breadcrumbs{
	padding: 2rem 0;
}

.breadcrumbs__inner{
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.breadcrumbs__active{
	font-size: 2.1rem;
	font-weight: 600;
	color: #2d3436;
}

.breadcrumbs__item{
	margin-left: 2rem;
	padding-left: 1rem;
	font-size: 2.1rem;
	font-weight: 300;
	position: relative;
}

.breadcrumbs__item:before{
	content: "";
	display: block;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #009635 ;
	position: absolute;
	top: 50%;
	left: -10px;
	transform: translateY(-50%);
}

.shop__info{
	padding: 2rem 0;
}

.shop__info--inner{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.shop__info--img--inner{
	width: 350px;
	height: auto;
	flex-shrink: 0;
	margin-right: 4rem;
}

.shop__info--img, .shop__info--img--mob{
	width: 100%;
	height: auto;
}

.shop__info--img--mob{
	display: none;
}

.shop__info--text--inner{
	font-size: 1.8rem;
	color: #34495e;
	font-weight: 300;
}

.bold{
	font-size: 2.9rem;
	font-weight: 600;
	color: #34495e;
}

.shop__city--content, .shop__city--item{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.shop__city--item:first-child{
	display: flex!important;
}

.shop__city--item{
	margin-top: 2rem;
}

.shop__city--item--fade{
	display: none;
}

.shop__city--letter{
	font-size: 3rem;
	color: #34495e;
	font-weight: 600;
}

.shop__city--names--inner{
	width: 100%;
	margin-top: 1rem;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.shop__city--name{
	color: #34495e;
	font-weight: 600;
	font-size: 1.5rem;
	width: 16%;
	text-align: left;
}

.shop__city--button--inner{
	width: 100%;
	margin-top: 2rem;
	display: flex;
	justify-content: flex-end;
}

.shop__city--show--button{
	background: #009635 ;
	border-radius: 50px;
	padding: .7rem 2rem;
	color: #fff;
	font-size: 1.5rem;
	font-weight: 600;
}

.shop__points{
	padding: 2rem 0;
}

.shop__points--inner{
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.shop__points--box{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-right: 3rem;
	width: 100%;
	max-width: 535px;
}

.shop__points--item{
	display: flex;
	margin-top: 3.5rem;
	transition: .2s linear;
}

.shop__points--item:hover{
	box-shadow: 0 0 25px #009635 ;
	background: #e9e9e9;
}

.shop__points--item:first-child{
	margin-top: 0;
}

.shop__points--img--inner{
	border-radius: 7px 0 0 7px;
	background: #009635 ;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2rem;
	width: 100%;
	max-width: 112px;
}

.shop__points--info--inner{
	padding: 1.5rem;
	background: #f6f6f6;
	border-radius: 0px 7px 7px 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.shop__points--info--title{
	font-size: 2.3rem;
	color: #34495e;
	font-weight: 600;
}

.shop__points--info--text{
	margin-top: 1rem;
	color: #34495e;
	font-weight: 300;
	font-size: 1.6rem;
}

.fa-bars.header__menu--icon{
	display: none;
	color: #009635 ;
	font-size: 3rem;
	transition: .2s linear;
}

.fa-bars.header__menu--icon:hover{
	opacity: .5;
}

.shop__points--carousel--inner{
	width: 55%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.align__left{
	align-items: flex-start;
}

.shop__points--button--inner{
	margin-top: 1rem;
	display: flex;
	align-items: center;
}

.shop__points--button{
	color: #fff;
	font-size: 2rem;
	font-weight: 600;
	padding: .7rem 1.5rem;
	border-radius: 50px;
	background: #009635 ;
	margin-right: 2rem;
}

.shop__points--button:last-child{
	margin-right: 0;
}

.shop__points--carousel--content{
	position: relative;	
	width: 100%;
}

.shop__points--carousel--img{
	width: 100%;
}

.shop__points--name--abs{
	position: absolute;
	bottom: 10px;
	left: 15px;
	color: #fff;
	font-size: 1.8rem;
	font-weight: 600;
	z-index: 10;
}

.shop__points--carousel--box .slick-dots{
	bottom: 10px;
	right: 15px;
	left: auto;
	top: auto;
	position: absolute;
}

.shop__points--carousel--box .slick-dots li.slick-active{
	background: #009635 ;
}

.info{
	padding: 1.5rem 0;
}

.info__inner{
	width: 100%;
	display: flex;
	align-items: flex-start;
}

.info__card--inner{
	width: 43%;
	flex-wrap: wrap;
	justify-content: space-between;
	display: flex;
	flex-shrink: 0;
	margin-right: 3rem;
}

.info__card--item{
	display: flex;
	align-items: center;
	padding: 2rem;
	background: #f6f6f6;
	border-radius: 10px;
	margin: 2rem 1.5rem 0 0;
	width: 46%;
	transition: .2s linear;
}

.info__card--item:hover{
	box-shadow: 0 0 25px #009635 ;
	background: darken(#f6f6f6, 5);
}

.info__card--item:nth-child(2n){
	margin-right: 0;
}

.info__card--item:nth-child(1), 
.info__card--item:nth-child(2){
	margin-top: 0;
}

.info__card--img--inner{
	margin-right: 1.5rem;
}

.filter__mobile--inner{
	display: none;
	margin-top: 2rem;
	width: 100%;
	justify-content: center;
}

.filter__mobile--button{
	padding: 1rem 2rem;
	border-radius: 50px;
	background: #009635 ;
	color: #fff;
	font-size: 1.8rem;
}

.info__card--title{
	font-size: 1.8rem;
	color: #34495e;
	font-weight: 600;
}

.info__text--inner{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
}

.info__text--title{
	font-size: 1.8rem;
	color: #34495e;
	font-weight: 600;
	margin-top: 1.5rem;
}

.info__text--title:first-child{
	margin-top: 0;
}

.info__text--text{
	color: #34495e;
	font-size: 1.5rem;
	font-weight: 300;
	margin-top: 1.5rem;
}

// Мобильное меню

.modal__menu{
	position: fixed;
	top: 0;
	left: -110%;
	width: 100%;
	height: 100vh;
	background: #fff;
	z-index: 50;
	transition: .3s linear;
}

.modal__menu.active{
	left: 0;
}

.scroll{
	overflow: hidden;
}

.modal__menu--inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-top: 5rem;
	position: relative;
}

.modal__menu--cross--inner{
	position: absolute;
	top: 0;
	right: 0;
	padding: .8rem;
	background: #000;
}

.modal__menu--search--inner{
	width: 100%;
	position: relative;
}

.modal__menu--search{
	width: 100%;
	border: 1px solid #e7e7e7;
	border-radius: 10px;
}

.modal__menu--search--icon{
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
}

.menu__modal--link{
	margin-top: 1rem;
	font-size: 1.8rem;
	color: #000;
	transition: .2s linear;
}

// Общая страница магазина

.shop__info--map--inner{
	width: 100%;
}

.address{
	padding: 2rem 0;
}

.address__inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.address__text{
	font-size: 1.7rem;
	color: #b7bcc2;
	font-weight: 300;
}

.address__link{
	color: #b7bcc2;
	text-decoration: underline;
}

.address__box{
	width: 100%;
	margin-top: 2rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
}

.address__item{
	padding: 2.5rem;
	border-radius: 7px;
	background: #f6f6f6;
	width: 48%;
	margin: 2rem 2rem 0 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.address__item:nth-child(2n){
	margin-right: 0;
}

.address__item--box{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 1rem;
}

.address__item--box:first-child{
	margin-top: 0;
}

.address__item--value{
	width: 60%;
	color: #fff;
	font-weight: 300;
	background: #009635 ;
	border-radius: 7px;
	padding: 1rem;
	margin-right: 2.5rem;
	transition: .2s linear;
}

.address__item--value a{
	color: #fff;
}

.address__item--value:hover{
	background: #00A01F;
}

.address__item--phone--inner{
	width: 38%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.address__item--phone{
	display: flex;
	align-items: flex-start;
	font-size: 2.1rem;
	color: #34495e;
	font-weight: 300;
	margin-top: .5rem;
}

.address__item--phone--box{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.address__item--phone--box a{
	margin-top: .2rem;
}

.address__item--phone:first-child{
	margin-top: 0;
}

.address__phone--icon{
	margin: .65rem .5rem 0 0;
	font-size: 1.4rem;
	color: #009635 ;
}

.address__item--address--inner{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 40%;
	margin-right: 3rem;
	padding-left: 2.5rem;
}

.address__item--address{
	color: #34495e;
	font-size: 1.9rem;
	font-weight: 300;
	position: relative;
	margin-top: .2rem;
	width: 100%;
}

.carousel__item .carousel__item--left .carousel__item--left--img--inner{
	border-radius: 8px 0 0 8px;
}

.address__item--address:before{
	content: "";
	display: block;
	width: 17px;
	height: 17px;
	border-radius: 50%;
	background: #913d8d;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0px;
}

.address__item--address:first-child{
	margin-top: 0;
}

.address__item--buttons{
	display: flex;
	align-items: center;
}

.address__item--button--karta, .address__item--button--marsh, .show__me--button{
	color: #fff;
	font-size: 1.7rem;
	padding: 1rem 1.5rem;
	font-weight: 300;
	margin-right: 3rem;
	background: #009635 ;
	border-radius: 7px;
}

.show__me--button{
	background: #007fff;
}

.address__item--button--marsh{
	margin-right: 0;
	background: #34495e;
}

.katalog__text{
	margin-top: 1rem;
	font-size: 1.5rem;
	color: #2d3436;
	font-weight: 300;
}

.katalog__button{
	font-weight: 300;
	font-size: 2.4rem;
	color: #fff;
	padding: .5rem 1rem;
	margin-top: 1rem;
	border-radius: 50px;
	background: #009635 ;
}

.tovar{
	padding: 1.5rem 0;
}

.tovar__inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.tovar__views--inner{
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.tovar__view--button, .tovar__cart--button{
	border-radius: 50px;
	padding: .5rem 1.5rem;
	font-weight: 300;
	font-size: 1.7rem;
	color: #fff;
	background: #34495e;
	margin: 1rem 1rem 0rem 0rem;
}

.tovar__cart--button{
	background: #009635 ;
}

.tovar__content{
	margin: 2rem 0;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.tovar__content--item{
	padding: 1.5rem 1rem;
	border-radius: 7px;
	position: relative;
	background: #fff;
	border: 1px solid #d7d7d7;
	margin: 2rem 1rem 0 1rem;
	width: 31%;
	display: flex;
	align-items: center;
	transition: .2s linear;
	cursor: pointer;
}

.tovar__content--item:hover{
	border: 1px solid #000;
}

.point-light{
	padding-left: 2.3rem;
}

.tovar__content--item--favorite{
	position: absolute;
	top: 10px;
	left: 13px;
	cursor: pointer;
	z-index: 10;
}

.tovar__item--img--inner{
	width: 163px;
	height: 170px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 1rem;
	flex-shrink: 0;
}

.tovar__item--text--inner{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.tovar__item--name{
	text-transform: uppercase;
	color: #34495e;
	font-size: 1.7rem;
	font-weight: 300;
	transition: .2s linear;
}

.tovar__item--name:hover, .tovar__item--rate:hover{
	opacity: .6;
}

.tovar__item--rate{
	margin-top: 1rem;
	transition: .2s linear;
}

.tovar__item--price{
	margin-top: 1rem;
	font-size: 1.7rem;
	font-weight: 300;
	color: #34495e;
}

.tovar__item--cart{
	margin-top: 1rem;
	display: flex;
	align-items: center;
	font-size: 1.8rem;
	color: #fff;
	font-weight: 300;
	padding: .5rem 1rem;
	border-radius: 50px;
	background: #34495e;
}

.tovar__item--cart--img{
	margin-right: 1rem;
}

.tovar__item--buy{
	margin-top: 1rem;
	font-size: 1.8rem;
	color: #fff;
	font-weight: 300;
	padding: .5rem 1rem;
	border-radius: 50px;
	background: #009635 ;
}

// 404 страница

.error{
	padding: 2rem 0;
}

.error__inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 50vh;
	justify-content: center;
}

.error__title{
	font-size: 3.5rem;
	color: #575b5d;
	font-weight: 300;
}

.error__input--inner{
	position: relative;
	width: 50%;
	margin: 3rem auto 0 auto;
}

.error__input{
	font-size: 2rem;
	padding: 1rem 8rem 1rem 2rem;
	border-radius: 50px;
	border: 2px solid #00961F;
	width: 100%;
}

.error__search--inner{
	width: 70px;
	height: 100%;
	border-radius: 50px;
	background: #00961F;
	position: absolute;
	top: 0;
	right: 0;
}

// Каталог товаров магазина

.katalog__text--section{
	padding: 1.5rem 0;
}

.katalog__text--inner{
	display: flex;
	width: 100%;
	align-items: center;
}

.katalog__text--img--inner{
	width: 140px;
	height: auto;
	margin-right: 2rem;
	flex-shrink: 0;
}

.katalog__text--img{
	width: 100%;
	height: auto;
}

.katalog__text--text{
	color: #2d3436;
	font-weight: 300;
	font-size: 1.9rem;
}

.katalog__item{
	background: #fff;
	border: 1px solid #d7d7d7;
	padding: 1.2rem;
	border-radius: 7px;
	margin: 2rem 1rem 0rem 1rem;
	width: 22%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	transition: .2s linear;
}

.katalog__item:hover{
	border: 1px solid #000;
}

.katalog__item--favorite{
	position: absolute;
	top: 10px;
	left: 8px;
}

.katalog__item--img--inner{
	width: 100%;
    height: 260px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.katalog__item--img{
	width: auto;
	height: auto;
	max-height: 260px;
	max-width: 100%;
}

.katalog__item--title{
	margin-top: 1rem;
	font-size: 1.7rem;
	text-transform: uppercase;
	font-weight: 300;
	color: #34495e;
	text-align: center;
	word-break: break-all;
	transition: .2s linear;
}

.katalog__item--price{
	margin-top: 1rem;
	font-weight: 600;
	color: #34495e;
	font-size: 1.5rem;
}

.katalog__cart--button{
	display: flex;
	align-items: center;
	font-size: 1.5rem;
	color: #fff;
	font-weight: 300;
	margin-top: 1rem;
	background: #34495e;
	border-radius: 50px;
	padding: .7rem 1rem;
}

.katalog__cart{
	margin-right: .5rem;
}

.katalog__buy{
	margin-top: .5rem;
	text-decoration: underline;
	color: #009635 ;
	font-size: 1.5rem;
	font-weight: 300;
}

.cart__button__inner{
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 8rem;
}

.katalog__info--inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-left: 2rem;
}

.katalog__info--title{
	font-size: 1.8rem;
	font-weight: 600;
	color: #34495e;
	margin-top: 2rem;
}

.katalog__info--title:first-child{
	margin-top: 0;
}

.katalog__info--text{
	font-size: 1.5rem;
	color: #34495e;
	font-weight: 300;
	margin-top: 0.5rem;
}

.sale{
	padding: 1.5rem 0;
}

.sale__inner{
	width: 100%;
	margin: 2rem 0;
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	justify-content: space-between;
}

.katalog__item--rate--inner{
	transition: .2s linear;
}

.katalog__item--rate--inner:hover, .katalog__item--title:hover{
	opacity: .5;
}

.dark{
	background: #1c1c1c;
}

.sale__item{
	width: 31%;
	display: flex;
	margin-right: 2rem;
	transition: .2s linear;
}

.sale__item:hover{
	opacity: .8;
	border-radius: 8px;
	box-shadow: 0 0 50px #009635 ;
}

.sale__item:last-child{
	margin-right: 0;
}

.watch__later{
	padding: 2rem 0;
	background: #f6f6f6;
}

.watch__later--inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.watch__later--box{
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.watch__later--box a{
	margin-right: 2.5rem;
}

.watch__later--img{
	margin: 2rem 2rem 0 0;
	width: 50px;
}

// Страница конкретного магазина

.way__item{
	width: 49%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 2rem;
}

.way__item--title{
	font-weight: 600;
	font-size: 1.8rem;
	color: #34495e;
}

.way__item--text{
	font-weight: 300;
	color: #34495e;
	font-size: 1.5rem;
}

.way__box{
	width: 100%;
	margin-top: 3rem;
	display: flex;
	align-items: center;
}

.way__map--inner{
	padding: .5rem .3rem;
	border-radius: 4px;
	background: #3CD41D;
	margin-right: 1rem;
	flex-shrink: 0;
}

.under{
	text-decoration: underline;
}

.way__box--inner{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.way__item--buttons{
	margin-top: 1rem;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.way__item--button{
	margin: 1rem 1rem 0rem 0;
	font-weight: 300;
	font-size: 1.6rem;
	border-radius: 50px;
	color: #fff;
	padding: .3rem 1rem;
	background: #3CD41D;
}

.single{
	padding: 1.5rem 0;
	background: #f6f6f6;
}

.single__inner{
	width: 100%;
	display: flex;
	align-items: center;
}

.single__img--inner{
	margin-right: 2rem;
	width: 186px;
	height: auto;
	flex-shrink: 0;
}

.single__img{
	width: 100%;
	height: auto;
}

.single__content--inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.single__title{
	font-size: 2.4rem;
	font-weight: 600;
	color: #34495e;
}

// Акции магазина

.akcii{
	padding: 2rem 0;
}

.akcii__inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.akcii__text{
	font-weight: 300;
	color: #2d3436;
	font-size: 1.8rem;
	margin-top: 1rem;
}

.akcii__box{
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
}

.akcii__item{
	margin-top: 2rem;
	padding: 1.5rem;
	border-radius: 10px;
	background: #f6f6f6;
	width: 48%;
	display: flex;
	align-items: center;
}

.akcii__logo--inner{
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 150px;
	margin-right: 1rem;
}

.akcii__logo--top{
	border: 3px solid #ff4b4c;
	border-radius: 7px 7px 0 0;
	padding: 3rem;
	color: #ff4b4c;
	font-size: 4rem;
	font-weight: 300;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}

.akcii__logo--bottom{
	border: 3px solid #ff4b4c;
	border-radius: 0 0 7px 7px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ff4b4c;
	text-align: center;
	color: #fff;
	font-weight: 300;
	font-size: 1.8rem;
}

.akcii__item--text--inner{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.akcii__item--text--title{
	font-weight: 600;
	font-size: 2rem;
	color: #2d3436;
}

.akcii__item--text--text{
	font-weight: 300;
	margin-top: 1rem;
	color: #2d3436;
	font-size: 1.7rem;
}

.akcii__item--text--date{
	margin-top: 1rem;
	font-weight: 600;
	color: #2d3436;
	font-size: 1.7rem;
}

.akcii__item--text--button{
	border-radius: 50px;
	padding: .8rem 1.2rem;
	background: #34495e;	
	color: #fff;
	display: flex;
	align-items: center;
	font-size: 1.5rem;
	margin-top: 1rem;
}

.ml1{
	margin-left: .5rem;
}

.fa-caret-right{
	position: relative;
	top: 2px;
	font-size: 1.6rem;
}

.week{
	padding: 2rem 0;
}

.week__inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.week__title{
	font-weight: 600;
	margin-top: 2rem;
	font-size: 2.1rem;
	color: #34495e;
}

.week__content{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.week__box{
	margin: 2rem 0;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.week__item{
	width: 22%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
	margin: 2rem 1rem 0 1rem;
}

.week__item--img--inner{
	width: 100%;
	height: 250px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.week__item--img{
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 250px;
}

.week__item--text{
	text-transform: uppercase;
	font-weight: 600;
	font-size: 2rem;
	margin-top: 1rem;
	color: #34495e;
	transition: .2s linear;
}

.week__item--text:hover{
	opacity: .5;
}

.week__item--price--inner{
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	top: 50px;
	right: 45px;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background: rgba(255, 118, 117, .6);
	justify-content: center;
	text-align: center;
}

.week__item--delete--price{
	font-size: 1.5rem;
	font-weight: 300;
	text-decoration: line-through;
}

.week__item--price{
	margin-top: .3rem;
	color: #fff;
	font-size: 2.1rem;
}

.week__sale--inner{
	position: absolute;
	top: 45px;
	right: 40px;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background: #34495e;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-weight: 300;
	font-size: 1.3rem;
}

.pagination__inner{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 2rem 0;
}

.pagination__button, .page-numbers{
	margin-right: 1rem;
	border-radius: 50%;
	width: 40px;
	color: #fff;
	font-weight: 300;
	font-size: 2.1rem;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #34495e;
}

.pagination__button:last-child, .page-numbers:last-child{
	margin-right: 0;
}

.page-numbers:last-child, .page-numbers:first-child{
	border-radius: 0;
	width: auto;
	color: #000;
	height: auto;
	display: inline-block;
	padding: .8rem 1.5rem;
	border-radius: 40px;
	color: #fff;
	background: #34495e;
}

// Модалка промокодов

.promok{
	background: #e6e6e6;
	border-radius: 15px;
	padding: 4.5rem 1.5rem 1.5rem 1.5rem;
	position: fixed;
	z-index: 10;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	max-width: 450px;
	display: none;
	flex-direction: column;
	align-items: flex-start;
}

.promok.active{
	display: flex;
}

.promok__img--inner{
	width: 100px;
	height: auto;
}

.promok__img{
	width: 100%;
	height: auto;
}

.promok__title{
	font-weight: 600;
	font-size: 2.4rem;
	color: #243443;
	margin-top: 1rem;
}

.promok__date{
	color: #243443;
	font-weight: 300;
	font-size: 1.4rem;
}

.promok__date--bold{
	font-weight: 600;
}

.promok__text{
	color: #243443;
	font-size: 1.7rem;
	font-weight: 600;
	margin-top: 1rem;
}

.promok__button{
	padding: .5rem 2rem;
	border-radius: 9px;
	color: #fff;
	background: #009635 ;
	font-size: 1.5rem;
	font-weight: 300;
	margin-top: 1rem;
}

.promok__promo--button{
	padding: .5rem 2rem;
	border-radius: 9px;
	color: #fff;
	background: #243443;
	font-size: 1.5rem;
	font-weight: 300;
	margin-top: 1rem;
	border: 3px double #fff;
}

// Страница промокодов

.promo{
	padding: 1.5rem 0;
}

.promo__inner{
	width: 100%;
	display: flex;
	align-items: center;
	padding: 1.2rem;
	border-radius: 10px;
	background: #f5f5f5;
	width: 100%;
}

.promo__img--item, .promo__text--item{
	width: 45%;
	margin-right: 1rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.promo__text--item{
	width: 55%;
	margin-right: 0;
}

.promo__img--box{
	width: 100%;
	height: auto;
}

.promo__img{
	width: 100%;
	height: auto;
}

.promo__text, .promo__title{
	margin-top: 1rem;
	font-size: 1.5rem;
	color: #34495e;
	font-weight: 300;
}

.promo__text:first-child, .promo__title:first-child{
	margin-top: 0;
}

.promo__title{
	font-weight: 600;
	font-size: 1.8rem;
}

.promo__link--inner{
	margin-top: 1rem;
	display: flex;
	align-items: center;
}

.promo__link{
	margin-left: 1rem;
	border-bottom: 1px solid #575b5d;
	font-size: 2.1rem;
	color: #575b5d;
	font-weight: 300;
	transition: .2s linear;
}

.promo__link:hover{
	opacity: .5;
}

.promo__rate--inner{
	margin-top: 1rem;
}

.card__text--inner{
	width: 100%;
	margin-top: 3rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.card__title{
	text-align: center;
	font-size: 1.9rem;
	color: #aab1b8;
	font-weight: 600;
}

.card__text{
	margin-top: 1rem;
	font-size: 1.7rem;
	color: #aab1b8;
	font-weight: 300;
	text-align: center;
}

// Карта лояльности

.card{
	padding: 2rem 0;
}

.watch__later--img--big{
	width: 90px;
}

.dark .tovar__item--img{
	border-radius: 15px;
}

.card__inner{
	width: 100%;
	background: #f6f6f6;
	border-radius: 10px;
	padding: 7rem 2rem 3rem 2rem;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.card__img--inner{
	width: 463px;
	height: auto;
	flex-shrink: 0;
}

.card__img{
	width: 100%;
	height: auto;
}

.cards__text--inner{
	margin-right: 2rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.cards__title{
	font-size: 4.9rem;
	color: #34495e;
	font-weight: 600;
}

.cards__text{
	font-weight: 300;
	margin-top: 1rem;
	font-size: 1.5rem;
	color: #34495e;
}

.cards__subtitle{
	font-weight: 600;
	margin-top: 1rem;
	font-size: 1.8rem;
	color: #34495e;
}

.card__buttons{
	display: flex;
	position: absolute;
	top: -35px;
	right: 30px;
}

.card__button{
	transform: translateY(50%);
	padding: .7rem 2rem;
	font-weight: 300;
	color: #fff;
	font-size: 2rem;
	text-align: center;
	transform: skewX(-10deg);
	border-radius: 7px;
}

.card__activated{
	background: #009635 ;
	margin-right: 2rem;
}

.card__register{
	background: #27ae60;
	margin-right: 2rem;
}

.card__balance{
	background: #5b6a79;
}

// Доставка

.delivery{
	padding: 13rem 0;
	background: url("../img/delivery-bg.jpg") center no-repeat;
	background-size: cover;
	margin-top: 4rem;
}

.delivery__inner{
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.delivery__title{
	font-weight: 600;
	font-size: 4.7rem;
	color: #33393b;
}

.delivery__subtitle{
	font-weight: 300;
	color: #33393b;
	font-size: 3.8rem;
}

.delivery__button{
	background: #009635 ;
	margin-right: 2rem;
}

.delivery__buttons{
	top: -165px;
}

.delivery__how{
	padding: 1rem 0;
}

.delivery__how--inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.delivery__how--link{
	text-decoration: underline;
	font-weight: 300;
	font-size: 2rem;
	color: #2d3436;
}

.delivery__how--text{
	font-weight: 300;
	color: #2d3436;
	font-size: 1.8rem;
	margin-top: .5rem;
}

.delivery__icon{
	margin: 1rem 0 0 1rem;
}

.delivery__item{
	margin-top: 2rem;
	border-radius: 10px;
	background: #f6f6f6;
	padding: 1.5rem 2rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.delivery__item--title{
	font-size: 2.1rem;
	color: #2d3436;
	font-weight: 600;
}

.delivery__item--text{
	margin-top: 2rem;
	font-weight: 300;
	font-size: 1.9rem;
	color: #2d3436;
}

.delivery__item--date--inner{
	width: 100%;
	margin-top: 2rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.delivery__item--button{
	font-size: 1.9rem;
	color: #fff;
	font-weight: 300;
	padding: .5rem 2rem;
	border-radius: 50px;
	background: #009635 ;
}

.free__button{
	background: #27ae60;
}

.mt0{
	margin-top: 0;
}

.target{
	padding: 2rem 0rem 9rem 0;
	background: url("../img/delivery-bg2.jpg") center no-repeat;
	background-size: cover;
	margin-top: 2rem;
}

.target__inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.target__box{
	width: 100%;
	margin-top: 4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.target__input--inner{
	position: relative;
	margin-top: .3rem;
}

.target__text{
	font-size: 2.1rem;
	color: #2d3436;
	font-weight: 300;
}

.target__input{
	color: #2d3436;
	font-size: 2.6rem;
	border-radius: 13px;
	border: 2px solid #487eb0;
	width: 475px;
	padding-right: 18rem;
}

.target__button{
	background: #487eb0;
	border-radius: 0 13px 13px 0;
	color: #fff;
	font-weight: 300;
	font-size: 2.6rem;
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 170px;
}

.kurdelivery__info{
	padding: 2rem 0;
	display: none;
}

.kurdelivery__info--inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.reviews__text{
	padding: 2rem 0;
}

.reviews__text--inner{
	width: 100%;
}

.reviews__text--img--inner{
	width: 150px;
	height: auto;
	float: left;
	margin-right: 3rem;
}

.reviews__text--img{
	width: 100%;
	height: auto;
}

.revievs__icon{
	transition: .2s linear;
}

.revievs__icon:hover{
	opacity: .5;
}

.reviews__text--cont{
	margin-top: 1rem;
	font-size: 1.75rem;
	color: #34495e;
	font-weight: 300;
}

.reviews__text--cont:first-child{
	margin-top: 0;
}

.reviews__bold{
	font-weight: 600;
}

.revievs{
	padding: 2rem 0;
}

.revievs__inner{
	display: flex;
	width: 100%;
	align-items: flex-start;
	justify-content: space-between;
}

.revievs__item{
	width: 49%;
	margin-right: 2rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.revievs__item:last-child{
	margin-right: 0;
}

.revievs__content{
	margin-top: 2rem;
	border-radius: 10px;
	background: #f2f2f2;
	width: 100%;
	padding: 1.5rem 2rem;
	display: flex;
	align-items: flex-start;
}

.revievs_hh{
	margin-left: 1rem;
}

.dashicons-star-filled{
	color: #e4ad00;
}

.push{
	color: #f82c2d;
}

#share{
	width: 100%;
}

.social{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.revievs__content--img--inner{
	flex-shrink: 0;
	margin-right: 1rem;
	width: 106px;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.reviews__content--social--box{
	display: flex;
	align-items: center;
	margin-top: .5rem;
	justify-content: space-between;
	width: 100%;
}

.revievs__content--img{
	width: 100%;
	height: auto;
}

.revievs__text--inner{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.revievs__rate--inner{
	display: flex;
	align-items: center;
}

.revievs__rate--img{
	margin-right: .3rem;
}

.revievs__rate--img:last-child{
	margin-right: 0;
}

// Страница товара

.tovar{
	padding: 2rem 0;
}

.tovar__innner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.tovar__name--inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.tovar__name--title{
	font-size: 2.2rem;
	font-weight: 600;
	color: #2d3436;
}

.tovar__name--reviews--inner{
	margin-top: .5rem;
	display: flex;
	align-items: center;
	width: 100%;
	padding-bottom: 1rem;
	border-bottom: 1px solid #d0d0d0;
}

.tovar__name--rate--inner{
	width: 107px;
	height: auto;
	margin-right: 1rem;
}

.tovar__name--rate--img{
	width: 100%;
	height: auto;
}

.tovar__name--link{
	text-decoration: underline;
	margin-right: .5rem;
	font-size: 1.75rem;
	color: #2d3436;
	font-weight: 300;
}

.reviews__name--button{
	margin-top: 1rem;
	color: #fff;
	font-weight: 300;
	font-size: 1.7rem;
	border-radius: 50px;
	background: #27ae60;
	padding: .6rem 2rem;
}

.tovar__content--inner{
	width: 100%;
	margin-top: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.tovar__img--inner{
	width: 442px;
	height: auto;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.tovar__img{
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 450px;
}

.tovar__info--inner{
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.tovar__info--text{
	margin-top: .6rem;
	font-weight: 300;
	font-size: 1.75rem;
	color: #2d3436;
}

.tovar__info--title{
	margin-top: 1rem;
	font-weight: 600;
	font-size: 1.75rem;
	color: #2d3436;
}

.tovar__info--price--inner{
	margin-top: 7rem;
	display: flex;
	align-items: center;
}

.tovar__info--price{
	margin-right: 1rem;
	font-size: 2.6rem;
	color: #34495e;
	font-weight: 600;
}

.tovar__info--sale--button{
	background: #fff;
	border-radius: 50px;
	padding: .6rem 1rem;
	font-weight: 300;
	font-size: 1.6rem;
	color: #34495e;
	border: 1px solid #34495e;
}

.tovar__info--buy--inner{
	margin-top: .5rem;
	display: flex;
	align-items: center;
}

.tovar__info--biy{
	border-radius: 50px;
	padding: .6rem 2rem;
	background: #009635 ;
	color: #fff;
	font-weight: 300;
	font-size: 1.8rem;
	margin-right: 1rem;
}

.tovar__info--cart{
	width: 36px;
	height: 36px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #34495e;
}

// Главная страница торговых центров

.tc{
	padding: 1.5rem 0;
}

.tc__inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.tc__name--inner{
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.tc__name{
	font-size: 1.8rem;
	font-weight: 300;
	color: #fff;
	text-transform: uppercase;
	width: 35px;
	height: 35px;
	background: #1c257b;
	text-align: center;
	border-radius: 50%;
	margin: 1.5rem 1.5rem 0rem 0;
}

.tc__box{
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	width: 100%;
}

.tc__item{
	width: 20%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 2rem;
}

.tc__item--title{
	font-size: 4.8rem;
	font-weight: 600;
	color: #1c257b;
	text-transform: uppercase;
}

.tc__item--name--inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 2rem;
}

.tc__item--title--black{
	color: #000;
}

.tc__item--name{
	font-size: 300px;
	color: #1c1c1c;
	font-size: 1.9rem;
}

// Тц города

.tcgorod{
	padding: 1.5rem 0;
}

.tcgorod__inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.tcgorod__sort--inner{
	width: 100%;
	margin-top: 1rem;
	display: flex;
	justify-content: flex-end;
}

.tcgorod__select{
	color: #fff;
	font-weight: 300;
	padding: .3rem 1.5rem;
	border-radius: 7px;
	border: none;
	background: #ff4b38;
	font-family: 'Montserrat', sans-serif;
	font-size: 1.6rem;
}

.tcgorod__box{
	width: 100%;
	margin-top: 2rem;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.tcgorod__views{
	width: 20%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	top: 15px;
	position: sticky;
}

.tcgorod__content{
	width: 78%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.tcgorod__select--white, .shop__view--white{
	margin-top: 1rem;
	width: 100%;
	padding: .4rem 1.5rem;
	border: 1px solid #9b9e9f;
	border-radius: 5px;
	color: #a6a8a9;
	font-size: 1.6rem;
	font-family: 'Montserrat', sans-serif;
}

.tcgorod__select--white:first-child{
	margin-top: 0;
}

.shop__view--white{
	margin: 0rem 2rem 0rem 0rem;
}

.tcgorod__check--button{
	margin-top: 1rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ff4b38;
	border-radius: 5px;
	color: #fff;
	font-size: 1.6rem;
	font-weight: 300;
	padding: .6rem 1rem;
}

.tcgorod__map{
	margin-left: .5rem;
}

.tcgorod__maps{
	border-radius: 20px;
}

.tcgorod__content--item--address--buttons{
	margin-top: 1rem;
}

.item-4 .tcgorod__content--item--address--button,
.item-5 .tcgorod__content--item--address--button{
	padding: .5rem 1.5rem;
}

.slick-slide img{
	border-radius: 15px;
}

.tcgorod__content--box{
	width: 100%;
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.tcgorod__content--item{
	width: 31%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 8px;
	margin: 2rem 1rem 0rem 1rem;
}

.tcgorod__content--item.item-1{
	flex-direction: row;
	align-items: stretch;
}

.tcgorod__content--item.item-1 .tcgorod__content--item--bottom{
	border-radius: 0 8px 8px 0;
}

.item-1{
	width: 100%;
}

.item-2{
	width: 47.5%;
}

.item-4{
	width: 22.5%;
}

.item-5{
	width: 17.5%;
}

.item-4 .tcgorod__content--item--img,
.item-5 .tcgorod__content--item--img{
	border-radius: 8px 8px 0 0;
}

.item-5 .tcgorod__content--item--address--inner,
.item-4 .tcgorod__content--item--address--inner{
	flex-direction: column;
	align-items: flex-start;
}

.tc__buttons{
	width: 100%;
	margin-top: 1rem;
	display: flex;
	align-items: center;
}

.tcgorod__content--item--top, .tcgorod__content--item--bottom{
	width: 100%;
	display: flex;
}

.tcgorod__content--item--top{
	border-radius: 8px 8px 0 0;
	height: 200px;
	display: block;
}

.tcgorod__content--item--bottom{
	padding: 1rem;
	background: #f2f2f2;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 0 0 8px 8px;
}

.shoping__content{
	margin-top: 2rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.item-1 .tcgorod__content--item--img{
	border-radius: 8px 0 0 8px;
}

.shoping__content h2{
	margin-bottom: 2rem;
}

.tcgorod__content--item--img{
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 8px 8px 0 0;
}

.tcgorod__content--item--title{
	font-weight: 600;
	font-size: 2.4rem;
	color: #2d3436;
}

.tcgorod__content--item--text{
	font-size: 1.6rem;
	color: #2d3436;
	font-weight: 300;
}

.tcgorod__content--item--address--inner{
	width: 100%;
	margin-top: .5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.tcgorod__content--item--address{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.tcgorod__content--item--address--item{
	font-size: 1.9rem;
	font-weight: 300;
	color: #2d3436;
	position: relative;
	padding-left: 1.5rem;
}

.tcgorod__content--item--address--item:before{
	content: "";
	display: block;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #ff4b38;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
}

.tcgorod__content--item--address--buttons{
	display: flex;
}

.tcgorod__content--item--address--button{
	margin-right: .6rem;
	border-radius: 10px;
	background: #ff4b38;
	padding: .5rem;
}

.tcgorod__content--item--address--button:last-child{
	margin-right: 0;
}

// Каталог товаров торгового центра

.shop{
	padding: 1.5rem 0;
}

.shop__inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.shop__box{
	margin-top: 2rem;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
}

.shop__views--inner{
	margin-top: 2rem;
	display: flex;
	align-items: center;
}

.shop__search--inner{
	position: relative;
}

.tag__address .search{
	padding: 0;
}

.shop__search{
	font-size: 1.6rem;
	font-weight: 300;
	color: #a6a8a9;
	background: #fff;
	border: 1px solid #9b9e9f;
	border-radius: 8px;
	padding: .6rem 4rem .6rem 1rem;
}

.shop__search--img{
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
}

.shop__tovar--inner{
	width: 100%;
	margin-top: 2rem;
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.shop__tovar--item{
	width: 15%;
	margin: 2rem 1rem 0 1rem;
}

.shop__tovar--top{
	border-radius: 7px 7px 0 0;
	position: relative;
}

.shop__tovar--brend{
	position: absolute;
	top: 5px;
	left: 5px;
	width: 38px;
	height: auto;
	z-index: 10;
}

.shop__tovar--top, .shop__tovar--bottom{
	width: 100%;
	height: 145px;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	border: 1px solid #d7d7d7;
}

.shop__tovar--bottom{
	height: auto;
}

.shop__tovar--img{
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}

.shop__tovar--bottom{
	padding: 1rem;
	background: #f3f3f3;
	border-radius: 0 0 7px 7px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.shop__tovar--title{
	font-size: 1.9rem;
	word-break: break-all;
}

.shop__tovar--text{
	font-weight: 300;
	font-size: 1.5rem;
	color: #ababab;
}

.shop__tovar--price--inner{
	margin-top: .3rem;
	display: flex;
	align-items: center;
}

.shop__tovar--button{
	border-radius: 4px;
	background: #ff513e;
	padding: .5rem 2rem;
	color: #fff;
	font-weight: 300;
	font-size: 1.4rem;
	margin-top: .5rem;
}

.shop__tovar--price--delete{
	font-weight: 300;
	font-size: 1.5rem;
	color: #2d3436;
	text-decoration: line-through;
}

.shop__tovar--price{
	font-weight: 600;
	font-size: 1.9rem;
	color: #2d3436;
}

// Кинотеатр

.kino{
	padding: 1.5rem 0;
}

.kino__inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.kino__afisha--inner{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.kino__afisha--item{
	width: 48%;
	display: flex;
	padding: 1rem;
	border-radius: 5px;
	margin-top: 2rem;
	background: #f0f0f0;
}

.kino__content--inner{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-left: 2rem;
}

.kino__slider--inner{
	width: 48%!important;
}

.kino__afisha--img--inner{
	width: 200px;
	flex-shrink: 0;
	max-height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.kino__afisha--img{
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
	flex-shrink: 0;
}

.kino__afisha--title{
	font-weight: 600;
	font-size: 1.7rem;
	color: #2d3436;
}

.kino__afisha--text{
	font-size: 1.6rem;
	font-weight: 300;
	margin-top: 1rem;
	color: #2d3436;
}

.kino__afisha--rate{
	margin-top: 1rem;
}

.tcbeside{
	padding: 1.5rem 0;
}

.tcbeside__inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.tcbeside__content{
	flex-wrap: wrap;
	width: 100%;
	margin-top: 2rem;
	display: flex;
	justify-content: flex-start;
}

.kino__content{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 2rem;
}

.kino__text--inner{
	width: 48%;
	padding: 1.5rem;
	background: #f7f7f7;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.kino__text--item{
	width: 100%;
	display: flex;
	align-items: center;
	margin-top: 1rem;
}

.kino__text--item--icon{
	width: 27px;
	height: 27px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #009635 ;
	flex-shrink: 0;
	margin-right: 1rem;
}

.kino__text--item--text{
	color: #2d3436;
	font-weight: 300;
	font-size: 1.8rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
}

.kino__slider--inner .slick-dots{
	right: auto;
	left: 15px;
	top: auto;
	bottom: 10px;
	position: absolute;
}

ul{
	padding: 0;
	margin: 0;
}

.kino__slider--inner .slick-dots li.slick-active{
	background: #000;
}

// Комментарий привести к виду

.comment__content{
	width: 100%;
	padding: 1.5rem 0;
}

.comment__inner--content{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

// Тц магазины

.tcmagaziny{
	padding: 1.5rem 0;
}

.tcmagaziny__inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.all__filt--inner{
	width: 23%;
	margin-right: 2rem;
	flex-direction: column;
}

.tcmagazini__item{
	width: 46%;
	padding: 2rem;
	display: flex;
	align-items: center;
	background: #f7f7f7;
	margin: 2rem 1rem 0rem 1rem;
	transition: .2s linear;
}

.shoping__tc--item--box{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.shoping__tc--item--title{
	color: #2d3436;
	font-weight: 600;
	font-size: 2.1rem;
}

.shoping__tc--item--clock, .shoping__tc--item--etag, .shoping__tc--item--phone{
	margin-top: 4rem;
	display: flex;
	align-items: center;
	font-size: 1.6rem;
	color: #2d3436;
}

.shoping__tc--item--etag, .shoping__tc--item--phone{
	margin-top: 1rem;
}

.shoping__tc--item--icon{
	margin-right: .5rem;
	width: 19px;
	height: auto;
}

// Страница тц

.tc__button{
	padding: .6rem 1.5rem;
	border-radius: 10px;
	border: 1px solid #009635 ;
	color: #009635 ;
	background: #fff;
	font-weight: 300;
	font-size: 2rem;
	transition: .2s linear;
}

.tc__shop--button{
	margin-right: 2rem;
}

.tc__button:hover{
	background: #009635 ;
	color: #fff;
}

.tc__names--inner{
	width: 100%;
	flex-wrap: wrap;
	display: flex;
	align-items: center;
	margin-top: 2rem;
}

.tc__name{
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background: #009635 ;
	color: #fff;
	text-transform: uppercase;
	font-weight: 300;
	font-size: 1.7rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: .7rem .7rem 0rem 0;
}

.tc__view--button{
	padding: .5rem 1.5rem;
	background: #009635 ;
	color: #fff;
	border-radius: 10px;
	font-weight: 300;
	font-size: 1.8rem;
	margin: 1rem 1rem 0rem 0;
}

.tc__item--inner{
	width: 100%;
	flex-wrap: wrap;
	display: flex;
	margin-top: 2rem;
}

.tcpage__item{
	width: 31%;
	margin: 2rem 2rem 0rem 0;
	display: flex;
	transition: .2s linear;
}

.tc__item--left{
	padding: 1rem;
	border-radius: 10px 0 0 10px;
	background: #009635 ;
	width: 35%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.tc__item--right{
	background: #f7f7f7;
	padding: 1rem;
	border-radius: 0 10px 10px 0;
	width: 65%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.tc__img{
	width: 100%;
	height: auto;
}

.shop__box a{
	width: 80px;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 1rem 2rem 0 0;
}

.shop__box a img{
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 80px;
}

.tcpage__item--title{
	font-weight: 600;
	font-size: 2.5rem;
}

.tc__item--phone{
	margin-top: 1.5rem;
	font-weight: 300;
	font-size: 1.8rem;
}

.reviews__title, .reviews__title h2{
	color: #fff;
}

.tc__item--website{
	font-weight: 300;
	font-size: 1.8rem;
}

.carousel__item__tc{
	display: flex!important;
}

.carousel__item__tc .carousel__item--left{
	max-width: 100%;
	padding: 0;
	border-radius: 8px 0 0 8px;
	width: 300px;
	flex-shrink: 0;
}

.carousel__item-tc--img{
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 8px 0px 0px 8px;
}

.carousel__item__tc.slick-slide img{
	border-radius: 8px 0 0 8px;
	width: 100%;
	height: auto;
}

.tc__map{
	padding: 1.5rem 0;
	width: 100%;
}

.tc__map__inner{
	width: 100%;
}

.tc__mappage{
	height: 450px;
}

.comment-form-author label{
	display: none;
}

// Страница города

.catalog__tovar{
	padding: 1.5rem 0 8rem 0;
}

.catalog__tovar__inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.tag{
	padding: 1.5rem 0;
}

.tc__map--inner{
	width: 100%;
	margin-top: 1rem;
}

.tag__inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.tag__fade--button{
	font-size: 1.6rem;
	font-weight: 300;
	color: #b8baba;
	margin: 1.5rem 0rem 0rem 0;
}

.tag__content{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.tag__item{
	margin: 2rem 0rem 0rem 0rem;
	width: 47%;
	background: #f7f7f7;
	padding: 1rem;
	display: flex;
	align-items: flex-start;
	transition: .2s linear;
}

.tag__cont--box{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.tag__item--title{
	text-align: center;
	font-size: 2.5rem;
	font-weight: 600;
}

.tag__item--clock, .tag__item--phone, .tag__item--address{
	margin-top: 3rem;
	font-weight: 300;
	font-size: 1.7rem;
	display: flex;
	align-items: center;
}

.tag__item--phone, .tag__item--address{
	margin-top: .2rem;
}

.tag__address{
	margin-top: .5rem;
	display: flex;
	align-items: center;
}

.tag__img--inner{
	width: 150px;
	height: 148px;
	margin-bottom: 2rem;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	flex-shrink: 0;
	margin-right: 1rem;
}

.tag__map--img--inner, .tag__way--img--inner{
	padding: .5rem;
	border-radius: 10px;
	margin-left: .5rem;
	background: #009635 ;
	height: auto;
	width: 35px;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.tag__map--img{
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 27px;
}

.tag__item--icon{
	width: 18px;
	height: auto;
	margin-right: .5rem;
}

.tag__img--inner:before{
	content: "";
	display: block;
	width: 50px;
	height: 2px;
	background: #d63031;
	position: absolute;
	bottom: -20px;
	left: 50%;
	transform: translateX(-50%);
}

.tag__img{
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 140px;
}

.com_block_star label{
	display: none;
}

.comment-form-cookies-consent label{
	font-size: 1.3rem;
	color: #a7a7a7;
}

.comment-form-cookies-consent{
	width: 100%;
	max-width: 550px;
	text-align: center;
	margin-top: 1rem;
}

.comments-rating{
	width: 100%;
}

.rating-container{
	width: 100%;
}

.com_block_star{
	width: 100%;
	max-width: 550px;
}

// Контакты

.feedback{
	padding: 1.5rem 0;
}

.feedback__inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.feedback__content{
	width: 100%;
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.feedback__textarea{
	width: 100%;
	font-weight: 300;
	color: #919394;
	font-size: 1.9rem;
	background: #f3f3f3;
	border-radius: 15px;
	border: 1px solid #d2d2d2;
	height: 180px;
}

.feedbackk__input--inner{
	width: 100%;
	display: flex;
	margin-top: 2rem;
}

.feedback__input{
	font-weight: 300;
	color: #919394;
	font-size: 1.9rem;
	background: #f3f3f3;
	border-radius: 7px;
	border: 1px solid #d2d2d2;
	max-width: 320px;
	width: 100%;
	padding: .5rem 1rem;
	margin-right: 2rem;
}

.feedback__recapcha{
	margin-right: 2rem;
}

.feedback__send--button{
	background: #009635 ;
	padding: .5rem 6rem;
	color: #fff;
	font-weight: 300;
	font-size: 1.9rem;
	border-radius: 7px;
}

// Корзина

.cart{
	padding: 1.5rem 0;
}

.cart__inner{
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.cart__tovar--inner{
	width: 64%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.cart__tovar--item{
	width: 100%;
	display: flex;
	margin: 2rem 0rem 0rem 0rem;
}

.cart__tovar--item:first-child{
	margin-top: 0;
}

.cart__total--inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 1rem;
	border-radius: 7px;
	background: #f3f3f3;
}

.cart__total--text{
	font-size: 2.3rem;
	font-weight: 300;
	color: #2d3436;
}

.cart__total--text .bold{
	font-size: 2.3rem;
}

.cart__total--button{
	margin-top: 3rem;
	width: 100%;
	padding: .6rem 2rem;
	border-radius: 8px;
	background: #009635 ;
	color: #fff;
	font-size: 2.3rem;
	font-weight: 300;
}

.cart__total--content{
	width: 34%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: 2rem;
}

.cart__total--email{
	margin-top: 1rem;
	color: #97999a;
	font-weight: 300;
	font-size: 1.8rem;
}

.cart__tovar--img--inner{
	width: 30%;
	border-radius: 14px 0 0 14px;
	padding: 1rem 5rem;
	background: #fff;
	display: flex;
	justify-content: center;
	border: 1px solid #e2e2e2;
	border-right: none;
	align-items: center;
}

.cart__tovar--img{
	width: 100%;
	height: auto;
}

.cart__tovar--content--inner{
	width: 70%;
	padding: 1.5rem;
	background: #fff;
	border: 1px solid #e2e2e2;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	border-radius: 0 14px 14px 0;
}

.cart__tovar--content--title{
	color: #2d3436;
	font-size: 1.8rem;
	font-weight: 600;
}

.cart__tovar--content--text{
	font-weight: 300;
	color: #2d3436;
	font-size: 1.6rem;
	margin-top: 2rem;
}

.cart__tovar--box{
	width: 87%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.cart__tovar--price--inner{
	width: 100%;
	margin-top: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.cart__tovar--price{
	font-weight: 600;
	font-size: 1.9rem;
	color: #2d3436;
}

.cart__tovar--bonus{
	font-weight: 300;
	font-size: 1.5rem;
	color: #2d3436;
}

.cart__tovar--value--inner{
	border-radius: 10px;
	border: 1px solid #c1c1c1;
	padding: .6rem;
	display: flex;
	align-items: center;
}

.cart__tovar--value--edit{
	font-weight: 300;
	font-size: 1.6rem;
	color: #2d3436;
	cursor: pointer;
	margin-right: 2rem;
}

.cart__tovar--value--edit:last-child{
	margin-right: 0;
}

.cart__tovar--value{
	margin-right: 2rem;
	font-weight: 300;
	font-size: 2rem;
	color: #2d3436;
}

.cart__abs--buttons{
	display: flex;
	position: absolute;
	top: 0;
	right: 0;
}

.cart__abs--button{
	padding: .5rem 1rem;
}

.cart__favorite--button{
	border-radius: 0 0 0 10px;
	background: #2d3436;
	border-right: 1px solid #fff;
}

.cart__delete--button{
	background: #db5e5e;
	border-radius: 0 10px 0 0;
}

// Поиск

.search{
	padding: 1.5rem;
}

.search__inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.search__target{
	width: 100%;
}

.search__target{
	font-size: 2rem;
	color: #575b5d;
	font-weight: 300;
}

.search__bold{
	font-weight: 600;
	font-size: 2rem;
	color: #575b5d;
}

.search__filter--inner{
	margin-top: 2rem;
	width: 100%;
	padding: 2rem 1rem;
	border-radius: 10px;
	background: #f1f1f1;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.search__icon{
	width: 18px;
	height: 8px;
	display: flex;
}

.search__icon--img{
	width: 100%;
	height: auto;
}

.search__filter{
	font-size: 1.7rem;
	color: #575b5d;
}

.search__select{
	border: none;
	background: none;
	font-size: 1.7rem;
	color: #575b5d;
}

.search__content{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.search__item{
	width: 48%;
	display: flex;
	margin-top: 2rem;
	border: 1px solid #d7d7d7;
	border-radius: 10px;
}

.search__item--img--inner{
	padding: 1rem 2rem;
	width: 35%;
	background: #fff;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px 0 0 10px;
	border-right: 1px solid #e2e2e2;
}

.search__item--img{
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 200px;
}

.search__item--text--inner{
	width: 65%;
	padding: 1rem;
	border-radius: 0 10px 10px 0;
	background: #f3f3f3;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.search__item--text--title{
	font-weight: 600;
	font-size: 2rem;
}

.search__item--text--text{
	font-size: 1.8rem;
	font-weight: 300;
	margin-top: 2rem;
}

.search__price--inner{
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-top: 2rem;
	width: 100%;
}

.search__price--img--inner{
	width: 71px;
	height: 71px;
	flex-shrink: 0;
}

.search__price--img{
	width: 100%;
	height: 100%;
}

.search__price--box{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.search__price{
	font-size: 1.8rem;
	font-weight: 600;
}

.search__button--inner{
	margin-top: .5rem;
	display: flex;
	align-items: center;
}

.search__view--link{
	font-size: 1.6rem;
	text-decoration: underline;
	color: #717475;
}

.search__cart--button{
	padding: .5rem 1rem;
	margin-right: 1rem;
	border-radius: 6px;
	background: #009635 ;
	color: #fff;
	font-size: 1.7rem;
}

.search__tovar--button{
	margin-top: 2rem;
	padding: .5rem 1rem;
	border-radius: 6px;
	background: #009635 ;
	color: #fff;
	font-size: 2rem;
}

.cart__input{
	width: 100%;
	margin-top: 1rem;
}

.cart__textarea{
	margin-top: 1rem;
	width: 100%;
}

.tag__item--cont{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	overflow-y: hidden;
	position: relative;
}

.tag__address--fade{
	width: 100%;
	display: none;
	flex-direction: column;
	align-items: flex-start;
}

.compolya{
	width: 100%;
	max-width: 33%;
	margin-right: 1rem;
}

// Рейтинг комментариев

.all_com_pr span:before{color:#FB9C30;}
.com_block_star{margin:10px 0;}
.comments-rating{border: none;padding: 0;margin-left: 0;}
.comments-rating label{display:inline-block;font-size:18px;}
.rating-container{font-size: 0;unicode-bidi: bidi-override;direction: rtl;}
.rating-container * {font-size: 1.4rem;}
.rating-container > input {display: none;}
.rating-container > input + label {
	font-family:'dashicons';
	display: inline-block;
	overflow: hidden;
	text-indent: 9999px;
	width: 1em;
	white-space: nowrap;
	cursor: pointer;
	margin: 0;
}

.rating-container > input + label:before {
	display: inline-block;
	text-indent: -9999px;
	content: "\f154";
	color: #888;
}

.rating-container > input:checked ~ label:before,
.rating-container > input + label:hover ~ label:before,
.rating-container > input + label:hover:before {
	content: "\f155";
	color: #FB9C30;
	text-shadow: 0 0 1px #888;
}

.rating-container > .star-cb-clear + label {
	text-indent: -9999px;
	width: .5em;
	margin-left: -.5em;
}

.rating-container > .star-cb-clear + label:before {
	width: .5em;
}

.rating-container:hover > input + label:before {
	content: "\f154";
	color: #888;
	text-shadow: none;
}

.rating-container:hover > input + label:hover ~ label:before,
.rating-container:hover > input + label:hover:before {
	content: "\f155";
	color: #FB9C30;
	text-shadow: 0 0 1px #888;
}

.reviews__title_hh{
	color: #fff;
}

// Категории

.view{
	padding: 1.5rem 0;
}

.view__inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.view__item{
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 15%;
	background: #fff;
	border: 2px solid #d7d7d7;
	margin: 1rem 1rem 0rem 1rem;
	border-radius: 10px;
}

.view__img--inner{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 150px;
	overflow: hidden;
}

.view__img{
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 150px;
}

.view__title{
	margin-top: 1rem;
	font-weight: 600;
	font-size: 2rem;
	text-align: center;
	word-break: break-all;
}

.view__title{
	font-size: 1.8rem;
}

.view__content{
	width: 100%;
	margin-top: 2rem;
	display: flex;
	flex-wrap: wrap;
}

.address__clock--text p,
.address__item--phone--box p{
	display: none;
	cursor: pointer;
}

.address__clock--text p:hover,
.address__item--phone--box p:hover{
	opacity: .6;
}

.address__clock--text p:first-child,
.address__item--phone--box p:first-child{
	display: block;
}

.recapcha__inner{
	margin-top: 1rem;
	width: 100%;
}

.screen-reader-text{
	display: none;
}

.searchform{
	width: auto;
}

#s{
	width: 100%;
	font-size: 2rem;
	padding: .5rem 8rem .5rem 2rem;
	border-radius: 50px;
	border: 2px solid #00961F;
	outline: none;
	font-family: 'Montserrat', sans-serif;
}

#searchform div{
	position: relative;
	width: 320px;
}

#searchform div:before{
	content: "";
	display: block;
	width: 20px;
	height: 20px;
	background: url("../img/search.png") center no-repeat;
	background-size: cover;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 24px;
	z-index: 1;
}

#searchsubmit{
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 70px;
	border-radius: 50px;
    background: #00961F;
    border: none;
    cursor: pointer;
    transition: .2s linear;
    outline: none;
}

#searchsubmit:hover{
	opacity: .6;
}

// Карусель игрушек

.week__carousel--inner, .shop__tovar--carousel{
	width: 100%;
	display: flex;
}

.week__carousel--item, .shop__tovar--cont--item{
	width: 100%;
	display: flex!important;
	flex-wrap: wrap;
}

.week__carousel--inner .slick-dots,
.shop__tovar--carousel .slick-dots{
	top: auto;
	bottom: auto;
	right: auto;
	left: auto;
	padding: 0;
}

.week__carousel--inner .slick-dots li button,
.shop__tovar--carousel .slick-dots li button{
	display: block;
	width: 40px;
	height: 40px;
	background: #34495e;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	border-radius: 50%;
	cursor: pointer;
	outline: none;
	font-family: 'Montserrat', sans-serif;
	font-size: 2rem;
}

.week__carousel--inner .slick-dots li,
.shop__tovar--carousel .slick-dots li{
	width: auto;
	height: auto;
	background: none;
	margin-right: 1rem;
	border-radius: 0;
	border: none;
}

.card__input{
	width: 100%;
	border-radius: 10px;
	padding: .7rem 1.5rem;
	margin-top: 1.5rem;
}

.card__input:first-child{
	margin-top: 0;
}

.card__modal--img--inner{
	width: 190px;
	height: auto;
	flex-shrink: 0;
	margin-right: 1rem;
}

.card__modal--img{
	width: 100%;
	height: auto;
}

.card__text{
	color: #000;
	font-size: 2rem;
}

.card__button--sms{
	padding: .6rem 2rem;
	border-radius: 10px;
	background: #009635 ;
	color: #fff;
	font-size: 1.8rem;
	margin-top: 1.5rem;
}

.reviews__title--inner{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.tag__fade--button{
	margin-top: 1rem;
	padding: .5rem 1rem;
	border-radius: 10px;
	background: #009635 ;
	color: #fff;
	font-weight: 300;
	font-size: 1.8rem;
}

.spamblock{
	margin-top: 1.5rem;
}

.card__cards--button{
	margin-top: 1rem;
	width: 100%;
	padding: .5rem 1rem;
	border-radius: 10px;
	color: #fff;
	font-size: 1.8rem;
	background: #009635 ;
}

.op0{
	opacity: 0;
}

#map{
	width: 100%!important;
}

// Дарк

.dark .footer, .dark #s, .dark .modal__menu{
	background: #1c1c1c;
}

.dark #s{
	color: #f6f6f6;
}

.dark .header__nav--item, .dark .breadcrumbs__active,
.dark .breadcrumbs__item, .dark .menu__modal--link{
	color: #fff;
}

.online{
	padding: 2rem 0;
}

.online__inner{
	width: 100%;
	display: flex;
	justify-content: center;
}

.online__content{
	width: 55%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.online__content--text{
	text-transform: uppercase;
	color: #fff;
	margin-top: 1rem;
	font-size: 2.4rem;
	font-weight: 300;
	text-align: center;
}

.online__content--subtext{
	text-transform: uppercase;
	color: #fff;
	margin-top: 2rem;
	font-size: 2rem;
	text-align: center;
	font-weight: 600;
}

.online__feedback--inner{
	border-radius: 12px;
	width: 100%;
	margin-top: 2rem;
	background: #009635 ;
	padding: 1.2rem 2rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.online__feedback--item--top{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.online__feedback--item--social--box{
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: 1rem;
}

.online__feedback--item--text{
	text-transform: uppercase;
	color: #1c1c1c;
	font-size: 3rem;
	margin-right: 1rem;
	font-weight: 600;
}

.online__feedback--item--social{
	width: 45px;
	height: 45px;
	border-radius: 50%;
	color: #fff;
	font-size: 2rem;
	background: #1c1c1c;
	display: flex;
	align-items: center;
	justify-content: center;
}

.online__feedback--item--social:last-child{
	margin-top: 1rem;
}

.online__feedback--item--input{
	color: #fff;
	border-radius: 8px;
	padding: 1.3rem .7rem;
	background: #1c1c1c;
	width: 100%;
	max-width: 280px;
	font-size: 2rem;
	font-weight: 300;
	margin-right: 1rem;
}

.online__feedback--item--input::placeholder{
	color: #fff;
}

.online__feedback--item--button{
	font-weight: 600;
	text-transform: uppercase;
	color: #fff;
	background: #1c1c1c;
	padding: 1.5rem .8rem;
	font-size: 3rem;
	border-radius: 8px;
}

.online__feedback--item--bottom{
	margin-top: 1rem;
	width: 100%;
	position: relative;
}

#dark__agree{
	display: none;
}

#dark__agree:checked + .online__feedback--label:after{
	opacity: 1;
}

.online__feedback--label{
	color: #1c1c1c;
	font-size: 2rem;
	font-weight: 300;
	padding-left: 5rem;
	cursor: pointer;
	display: block;
}

.online__feedback--label a{
	text-decoration: underline;
}

.online__feedback--label:before{
	content: "";
	display: block;
	width: 32px;
	height: 18px;
	border-radius: 4px;
	background: #1c1c1c;
	position: absolute;
	top: 3px;
	left: 6px;
}

.online__feedback--label:after{
	content: "";
	display: block;
	width: 9px;
	height: 9px;
	border-radius: 50%;
	background: #fff;
	position: absolute;
	top: 7px;
	left: 17px;
	opacity: 0;
	transition: .2s linear;
}

.not{
	width: 100%;
}

.not__img{
	width: 100%;
	height: auto;
}

.darklist{
	padding: 2rem 0;
}

.darklist__inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.darklist__title--inner{
	margin-bottom: 2rem;
}

.dark .tc__item--title{
	color: #009635 ;
}

.dark .tc__item--title--black{
	color: #fff;
}

.dark .tc__item--name{
	color: #f6f6f6;
}

.dark .tovar__cart--button{
	background: #009635 ;
	color: #1c1c1c;
}

.dark .tovar__content--item{
	background: #504f4f;
	border: none;
}

.dark .tovar__item--name, .dark .tovar__item--price{
	color: #fff;
}

.dark .tovar__item--cart{
	background: #009635 ;
}

.darkpromo{
	padding: 2rem 0;
}

.darkpromo__inner{
	width: 100%;
	display: flex;
	position: relative;
	justify-content: center;
}

.darkpromo__content{
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.darkpromo__content .akcii__item{
	width: 100%;
}

.dark .akcii__item{
	background: #504f4f;
}

.dark .akcii__logo--top{
	background: #1c1c1c;
	color: #009635 ;
	border: 3px solid #009635 ;
}

.dark .akcii__logo--bottom{
	color: #1c1c1c;
	border: 3px solid #009635 ;;
	background: #009635 ;
}

.dark .akcii__item--text--title,
.dark .akcii__item--text--text,
.dark .akcii__item--text--date{
	color: #fff;
}

.dark .akcii__item--text--button{
	color: #1c1c1c;
	background: #009635 ;
}

.darkpromo__sale--text{
	position: absolute;
	top: 0;
	left: 0px;
	z-index: -1;
}

.darkpromo__promo--text{
	position: absolute;
	bottom: 0;
	right: 0px;
	z-index: -1;
}

#timer22d3d00acda1eb98a003baf64dc35836{
	margin-top: 2rem;
}

.online__year{
	font-size: 5rem;
	color: #fff;
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
}

.carousel__item__tc .carousel__item--date{
	margin-top: 1rem;
}

.carousel__item__tc .carousel__item--name--inner{
	flex-direction: column;
	align-items: flex-start;
}

.carousel__item__tc .carousel__item--rate--inner{
	width: 100%;
}
















































// Футер

.footer{
	padding: 4rem 0rem 1rem 0;
	background: #2d3436;
}

.footer__inner{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.footer__nav--inner{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.footer__nav--box{
	display: flex;
	align-items: flex-start;
	width: 100%;
}

.footer__item{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	max-width: 210px;
	margin-right: 8rem;
}

.footer__item--title{
	color: #fff;
	font-size: 2.2rem;
	font-weight: 600;
}

.footer__item--link--inner{
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
}

.footer__item--link{
	color: #fff;
	font-weight: 300;
	font-size: 1.7rem;
	transition: .2s linear;
}

.footer__item--link:hover{
	opacity: .5;
}

.footer__logo--inner{
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.footer__logo--box{
	width: 264px;
	height: auto;
}

.footer__logo{
	width: 100%;
	height: auto;
}

.footer__logo--social--inner{
	display: flex;
	align-items: center;
	margin-top: 1rem;
}

.footer__logo--social--inner a{
	margin-right: 1rem;
	transition: .2s linear;
}

.footer__logo--social--inner a:hover{
	opacity: .5;
}

.footer__social--icon{
	color: #fff;
	font-size: 2rem;
}

.footer__social--icon:last-child{
	margin-right: 0;
}

.footer__copy{
	margin-top: 4rem;
	text-align: center;
	width: 100%;
	color: #6a6c6c;
	font-size: 1.7rem;
}

.footer__copy--link{
	text-decoration: underline;
	color: #777777;
	transition: .2s linear;
}

.footer__copy--link:hover{
	opacity: .5;
}

.shoping__link{
	text-align: center;
	font-size: 1.35rem;
	color: #2d3436;
	margin-top: .5rem;
	transition: .2s linear;
}

.shoping__link:hover{
	color: #000;
}

.shoping__link--box{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 1rem;
}

.shoping__title--inner{
	display: flex;
	width: 100%;
	align-items: center;
	flex-wrap: wrap;
}

.shoping__view--link{
	margin: 1rem 0 0 2rem;
	color: #a9aaab;
	transition: .2s linear;
	font-size: 1.6rem;
}

.shoping__view--link:hover{
	color: #000;
}

.shoping__text{
	color: #a9aaab;
	font-size: 1.5rem;
	margin: 1rem 0 0 2rem;
}

a img.carousel__item--left--img{
	border-radius: 8px 0px 0px 8px!important;
}

// Адаптив

@media(max-width: 1300px){
	.container{
		max-width: 1150px;
	}

	.view__item{
		width: 14.5%;
	}

	.shop__tovar--item{
		width: 18%;
	}

	.online__content{
		width: 60%;
	}

	.tovar__content--item{
		width: 31%;
	}
}

@media(max-width: 1200px){
	.container{
		max-width: 960px;
	}

	.header__logo--inner{
		width: 200px;
	}

	.header__search--input{
		width: 255px;
	}

	.input, #comment, #author{
		font-size: 1.6rem;
	}

	.header__nav--item{
		font-size: 1.8rem;
		margin-right: 1rem;
		padding: .5rem .6rem;
	}

	.main__title{
		font-size: 5.8rem;
	}

	.main__border{
		font-size: 4.3rem;
	}

	.main__subtitle{
		font-size: 3.1rem;
	}

	.city__map--ing{
		width: 530px;
	}

	.shoping__view--link{
		font-size: 1.8rem;
	}

	// Страница с магазинами

	.all__item{
		width: 29%;
		max-width: 240px;
		margin: 3rem 0rem 0rem 0rem;
	}

	// Информация о магазине

	.shop__info--text--inner{
		font-size: 1.6rem;
	}

	.shop__info--img--inner{
		width: 280px;
	}

	.shop__city--names--inner{
		flex-wrap: wrap;
	}

	.shop__points--button{
		font-size: 1.6rem;
	}

	.info__card--inner{
		width: 56%;
	}

	// Общая страница магазина

	.address__item{
		width: 49%;
		margin: 2rem 1rem 0rem 0;
		padding: 1.5rem;
	}

	.address__item--phone--inner{
		width: 45%;
	}

	.address__item--value{
		width: 50%;
		margin-right: 1rem;
		font-size: 1.8rem;
	}

	.tovar__content--item{
		width: 48%;
	}

	// Каталог магазина

	.katalog__item{
		width: 30%;
	}

	.sale__item{
		width: 48%;
		margin-top: 2rem;
	}

	.sale__item:last-child{
		margin-right: 2rem;
	}

	.sale__item:nth-child(2){
		margin-right: 0rem;
	}

	.card__inner{
		flex-direction: column-reverse;
		align-items: center;
		padding: 5rem 2rem;
	}

	.cards__text--inner{
		margin: 2rem 0rem 0rem 0;
	}

	.card{
		margin-top: 3rem;
	}

	.shop__city--name{
		width: 20%;
	}

	// Главная тц

	.tc__item{
		width: 25%;
	}

	// Тц города

	.tcgorod__content--item{
		width: 47%;
	}

	.shop__tovar--item{
		width: 22%;
	}

	.shoping__item{
		width: 22%;
	}

	.all__item--page{
		margin: 2rem 1rem 0rem 1rem;
	}

	// Поиск

	.search__button--inner{
		flex-direction: column;
		align-items: flex-start;
	}

	.search__cart--button{
		margin-right: 0;
	}

	.search__view--link{
		margin-top: .5rem;
	}

	.cart__tovar--img--inner{
		padding: 1rem 4rem;
	}

	.view__item{
		width: 31%;
	}

	.online__content{
		width: 70%;
	}

	.tovar__content{
		justify-content: space-between;
	}

	.tovar__content--item{
		margin: 2rem 0rem 0rem 0rem;
	}

	.kino__afisha--item{
		flex-direction: column;
		align-items: center;
	}

	.kino__afisha--title{
		margin-top: 1rem;
	}
}

@media(max-width: 998px){
	.container{
		max-width: 740px;
	}

	.main__title{
		font-size: 3.5rem;
	}

	.main__border{
		font-size: 2.8rem;
	}

	.main__subtitle{
		font-size: 2.3rem;
	}

	.main{
		padding: 8rem 0rem 7rem 0;
	}

	.city__map--ing{
		width: 430px;
	}

	.city__title{
		font-size: 2.4rem;
	}

	.city__name{
		font-size: 1.8rem;
	}

	.city__subinput{
		font-size: 1.4rem;
	}

	.footer__nav--inner{
		flex-direction: column;
		align-items: center;
	}

	.footer__logo--inner{
		margin-top: 3rem;
	}

	// Страница с магазинами

	.all__filter--inner{
		width: 27%;
	}

	.all__item{
		width: 100%;
		max-width: 100%;
		margin: 2rem 0rem 0rem 0rem;
	}

	// Информация о магазине

	.shop__points--inner{
		flex-direction: column;
		align-items: flex-start;
	}

	.shop__points--box{
		max-width: 100%;
		margin-right: 0;
	}

	.shop__points--carousel--inner{
		margin-top: 2rem;
		width: 100%;
	}

	.info__inner{
		flex-direction: column;
		align-items: center;
	}

	.info__text--inner{
		margin-top: 3rem;
	}

	.info__card--inner{
		width: 100%;
		margin-right: 0;
	}

	.add__reviews--comment--form, #comment{
		width: 430px;
	}

	.reviews__title, .comment-reply-title{
		font-size: 2.3rem;
	}

	// Общая страница магазина

	.address__item{
		width: 100%;
		margin: 2rem 0rem 0rem 0rem;
	}

	.address__item--box{
		justify-content: space-between;
	}

	.address__item--phone--inner{
		width: 30%;
	}

	.address__item--address--inner{
		width: 25%;
	}

	.katalog__button{
		font-size: 2rem;
	}

	.tovar__view--button, .tovar__cart--button{
		font-size: 1.5rem;
	}

	// Каталог магазина

	.katalog__item{
		width: 46%;
	}

	.katalog__info--text{
		font-size: 1.4rem;
	}

	.katalog__info--inner{
		margin: 3rem 0rem 0rem 0rem;
	}

	// Страница конкретного магазина

	.way__item{
		width: 100%;
	}

	// Акции магазина

	.akcii__item{
		width: 100%;
	}

	.week__item{
		width: 30%;
	}

	// Промокоды магазина

	.promo__inner{
		flex-direction: column;
		align-items: flex-start;
	}

	.promo__img--item, .promo__text--item{
		width: 100%;
	}

	.promo__text--item{
		margin-top: 1rem;
	}

	// Доставка

	.card__button{
		font-size: 1.7rem;
	}

	.delivery__title{
		font-size: 4rem;
	}

	.delivery__subtitle{
		font-size: 3.2rem;
	}

	// Отзывы

	.revievs__inner{
		flex-direction: column;
		align-items: flex-start;
	}

	.revievs__item{
		margin: 2rem 0 0 0;
		width: 100%;
	}

	// Товар

	.tovar__img--inner{
		width: 385px;
	}

	.revievs__item{
		align-items: center;
	}

	.tovar__info--price--inner{
		margin-top: 3rem;
	}

	.shop__city--name{
		width: 25%;
	}

	// Главная тц

	.tc__item{
		width: 33%;
	}

	.tc__name{
		width: 32px;
		height: 32px;
		margin: 1rem 1rem 0rem 0;
		font-size: 1.5rem;
	}

	// Тц города

	.tcgorod__box{
		flex-direction: column;
		align-items: flex-start;
	}

	.tcgorod__views{
		width: 100%;
	}

	.tcgorod__sort--inner{
		justify-content: flex-start;
	}

	.tcgorod__content{
		width: 100%;
		margin-top: 2rem;
	}

	.tcgorod__content--item:nth-child(2n){
		margin-right: 0;
	}

	.shop__tovar--item{
		width: 30%;
	}

	.kino__afisha--item{
		width: 49%;
	}

	.all__filter--inner.all__internet--filter{
		width: 100%;
	}

	.kino__content{
		flex-direction: column;
		align-items: flex-start;
	}

	.kino__slider--inner{
		width: 100%!important;
	}

	.kino__text--inner{
		margin-top: 2rem;
		width: 100%;
	}

	.shoping__item{
		width: 30%;
	}

	.tcmagazini__item{
		width: 100%;
		margin: 2rem 0rem 0rem 0;
	}

	// Страница тц

	.tcpage__item{
		width: 47%;
	}

	// Корзина

	.cart__inner{
		flex-direction: column-reverse;
	}

	.cart__total--content{
		width: 100%;
	}

	.cart__tovar--item:first-child{
		margin-top: 2rem;
	}

	.cart__tovar--inner{
		width: 100%;
		margin-right: 0;
	}

	// Поиск

	.search__item{
		width: 100%;
	}

	.view__item{
		width: 30%;
	}

	.online__content--text{
		font-size: 2rem;
	}

	.online__content--subtext{
		margin-top: 1rem;
		font-size: 1.8rem;
	}

	.online__content--title{
		width: 510px;
	}

	.online__feedback--item--social{
		width: 40px;
		height: 40px;
		font-size: 1.8rem;
	}

	.online__feedback--item--text{
		font-size: 2.5rem;
	}

	.online__feedback--item--input{
		max-width: 215px;
	}

	.online__feedback--item--button{
		font-size: 2rem;
	}

	.online__feedback--label{
		font-size: 1.8rem;
	}

	.darkpromo__content{
		width: 75%;
	}

	.darkpromo__sale--img, .darkpromo__sale--img{
		width: 100%;
	}

	.darkpromo__sale--text, .darkpromo__promo--text{
		width: 220px;
	}

	.spamblock{
		padding: .5rem .9rem;
	}

	.feedback__recapcha .g-recaptcha{
		transform: scale(1) !important;
	}

	.compolya .spamblock .g-recaptcha{
		transform: scale(0.69)!important;
	}
}

@media(max-width: 768px){
	.container{
		max-width: 450px;
	}

	.darkpromo__content{
		width: 100%;
	}

	.header__nav, .header__search--inner, #searchform{
		display: none;
	}

	.modal__menu--inner #searchform{
		display: block;
		width: 100%;
	}

	.tag__input--inner{
		margin-top: 1rem;
	}

	.compolya .spamblock .g-recaptcha{
		transform: scale(1)!important;
	}

	#searchform div{
		width: 100%;
	}

	.main__subtitle{
		font-size: 1.7rem;
	}

	.main__border{
		font-size: 1.8rem;
		padding: .4rem 1rem .5rem 1rem;
	}

	.main__title{
		font-size: 2.4rem;
	}

	.title{
		font-size: 1.8rem;
	}

	.shoping__view--link{
		font-size: 1.6rem;
		margin: 1rem 0rem 0rem 1rem;
	}

	.city__content{
		flex-direction: column;
		align-items: flex-start;
	}

	.footer__nav--box{
		flex-wrap: wrap;
	}

	.footer__nav--inner{
		align-items: flex-start;
	}

	.footer__copy{
		margin-top: 2rem;
		font-size: 1.5rem;
	}

	.footer__item{
		width: 100%;
		max-width: 50%;
		margin: 2rem 0 0 0;
	}

	.carousel__item{
		max-width: 100%;
		flex-direction: column;
		margin: 0rem 1.5rem;
	}

	.carousel__item--left{
		max-width: 100%;
		border-radius: 7px 7px 0 0;
	}

	.katalog__item{
		width: 100%;
		margin: 2rem 0rem 0rem 0;
	}

	.carousel__item--right{
		border-radius: 0 0 7px 7px;
	}

	.carousel__arrow{
		color: #000;
	}

	.city__map--inner, .city__box{
		width: 100%;
	}

	.city__box{
		margin: 2rem 0rem 0rem 1rem;
	}

	.fa-bars.header__menu--icon{
		display: block;
	}

	.city__map--ing{
		width: 100%;
	}

	// Страница с магазинами

	.all__content{
		flex-direction: column;
		align-items: flex-start;
	}

	.all__filter--inner{
		margin-right: 0;
		width: 100%;
	}

	.filter__mobile--inner{
		display: flex;
	}

	.all__filter--inner{
		display: none;
	}

	// Информация о магазине

	.shop__info--inner{
		flex-direction: column;
		align-items: center;
	}

	.shop__info--img--inner{
		width: 50%;
		margin-right: 0;
	}

	.shop__info--text--inner{
		margin-top: 2rem;
	}

	.shop__points--button--inner{
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}

	.shop__points--button{
		margin-top: 1.5rem;
	}

	.shop__points--button:first-child{
		margin-top: 0;
	}

	.info__card--item{
		width: 100%;
		margin: 2rem 0rem 0rem 0;
	}

	.info__card--item:nth-child(2n){
		margin-top: 2rem;
	}

	.add__reviews--content, .comment-form{
		flex-direction: column-reverse;
		align-items: center;
	}

	.comment-form{
		flex-direction: column;
	}

	.add__reviews--comment, .add__reviews--comment--form, #comment, .comment-form-comment{
		width: 100%;
	}

	.add__reviews--box{
		width: 55%;
		margin-top: 2rem;
	}

	.reviews__title, .comment-reply-title{
		font-size: 2rem;
	}

	.shop__info--map--inner{
		margin-top: 2rem;
	}

	// Общая страница магазина

	.address__text{
		font-size: 1.5rem;
	}

	.address__item--phone--inner{
		width: 45%;
	}

	.address__item--address--inner{
		margin-right: 1rem;
		width: 31%;
	}

	.address__item--address{
		font-size: 1.7rem;
	}

	.katalog__button{
		font-size: 1.7rem;
	}

	.tovar__content--item{
		width: 100%;
		margin: 2rem 0rem 0rem 0rem;
	}

	// 404 страница

	.error__input--inner{
		width: 100%;
	}

	.error__title{
		font-size: 2.7rem;
	}

	.compolya{
		max-width: 100%;
		margin: 2rem 0 0 0;
	}

	// Каталог магазина

	.katalog__text--inner{
		flex-direction: column;
		align-items: center;
	}

	.katalog__text--text{
		font-size: 1.7rem;
	}

	.sale__item{
		width: 100%;
		margin: 2rem 0 0 0;
	}

	.sale__item:last-child{
		margin-right: 0rem;
	}

	// Страница конкретного магазина

	.single__inner{
		flex-direction: column;
		align-items: center;
	}

	.single__img--inner{
		margin-right: 0;
	}

	.single__content--inner{
		margin-right: 2rem;
	}

	.single__title{
		font-size: 2rem;
	}

	.single__content--inner{
		margin-right: 0;
	}

	.week__item{
		width: 45%;
	}

	.shop__info--img{
		display: none;
	}

	.shop__info--img--mob{
		display: block;
	}

	.card__img--inner{
		width: 100%;
	}

	.card__button{
		margin-top: 1rem;
		margin-right: 0;
	}

	.card__button br{
		display: none;
	}

	.card__buttons{
		position: relative;
		top: 0;
		left: 0;
		flex-direction: column;
	}

	.card__inner{
		padding: 2rem;
	}

	.card{
		margin-top: 0;
	}

	.cards__title{
		font-size: 2.8rem;
	}

	// Доставка

	.delivery{
		padding: 2rem 0;
		margin-top: 0;
	}

	.delivery__title{
		font-size: 3.4rem;
	}

	.delivery__subtitle{
		font-size: 2.6rem;
	}

	.target__input{
		width: 100%;
		font-size: 2rem;
	}

	.target__button{
		font-size: 2rem;
	}

	// Отзывы

	.reviews__text--img--inner{
		width: 195px;
	}

	// Товар

	.tovar__content--inner{
		flex-direction: column;
		align-items: center;
	}

	.tovar__info--inner{
		width: 100%;
		margin-top: 2rem;
	}

	.modal__city{
		right: -55px;
		width: 440px;
	}

	.tovar__info--price--inner{
		margin-top: 1rem;
	}

	.shop__city--name{
		width: 50%;
	}

	// Главная тц

	.tc__item{
		width: 50%;
	}

	// Тц города

	.tcgorod__content--item{
		width: 100%;
		margin: 2rem 0 0 0;
	}

	.shop__views--inner{
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}

	.shop__view--white{
		margin: 1rem 0rem 0rem 0rem;
		width: 100%;
	}

	.shop__search--inner{
		width: 100%;
		margin-top: 1rem;
	}

	.shop__search{
		width: 100%;
	}

	.shop__tovar--inner{
		justify-content: space-between;
	}

	.shop__tovar--item{
		width: 48%;
		margin: 2rem 0 0 0;
	}

	.kino__afisha--item{
		width: 100%;
	}

	.shoping__content{
		justify-content: space-between;
	}

	.shoping__item{
		width: 48%;
		margin: 2rem 0rem 0rem;
	}

	// Страница тц

	.tcpage__item{
		width: 100%;
		margin: 2rem 0rem 0rem 0rem;
	}

	.tc__mappage{
		height: 350px;
	}

	.tag__content{
		justify-content: space-between;
	}

	// Контакты

	.feedbackk__input--inner{
		flex-direction: column;
		align-items: center;
		margin-top: 0;
	}

	.feedback__input{
		max-width: 100%;
		margin: 2rem 0rem 0rem 0rem;
	}

	.feedback__send--button{
		margin-top: 2rem;
	}

	.cart__total--text, .cart__total--text .bold, .cart__total--button{
		font-size: 1.8rem;
	}

	.cart__total--button{
		margin-top: 1rem;
	}

	.view__item{
		width: 48%;
		margin: 2rem 0rem 0rem 0rem;
	}

	.view__content{
		justify-content: space-between;
	}

	.feedback__recapcha{
		margin-right: 0;
		margin-top: 2rem;
	}

	.g-recaptcha{
		transform: scale(1)!important;
	}

	.feedback__send--button{
		width: 100%;
	}

	.online__content, .online__content--title{
		width: 100%;
	}

	.online__feedback--item--button{
		font-size: 1.8rem;
	}

	.online__feedback--item--input{
		max-width: 180px;
	}

	.darklist__title{
		width: 100%;
	}

	.darkpromo__sale--text, .darkpromo__promo--text{
		display: none;
	}

	#timer22d3d00acda1eb98a003baf64dc35836 span{
		font-size: 1.8rem;
	}

	#timer-text-22d3d00acda1eb98a003baf64dc35836-days,
	#timer-text-22d3d00acda1eb98a003baf64dc35836-hours,
	#timer-text-22d3d00acda1eb98a003baf64dc35836-minutes,
	#timer-text-22d3d00acda1eb98a003baf64dc35836-secundes{
		font-size: 1.6rem!important;
	}

	.compolya{
		width: 100%;
	}

	.spamblock{
		padding: 1.2rem;
		display: flex;
		justify-content: center;
	}

	.online__year{
		font-size: 3rem;
	}

	.feedback__recapcha .g-recaptcha{
		transform: scale(1) !important;
	}

	.kino__afisha--img--inner{
		max-height: 250px;
	}

	.carousel__item__tc .carousel__item--left{
		width: 200px;
	}
}

@media(max-width: 480px){
	.container{
		max-width: 300px;
	}

	.header__logo--inner{
		width: 135px;
	}

	.header__cart--content{
		display: none;
	}

	.main{
		padding: 4rem 0;
	}

	.main__subtitle{
		font-size: 1.5rem;
	}

	.main__title{
		font-size: 2.2rem;
	}

	.header__city--inner, .header__city--inner--mobile{
		padding: .45rem 1rem;
	}

	.shoping, .city{
		padding: 1.5rem 0;
	}

	.header{
		padding: 2rem 0;
	}

	.shoping__text{
		margin: 1rem 0rem 0rem 0rem;
	}

	.footer__item{
		max-width: 100%;
	}

	.footer__copy{
		font-size: 1.3rem;
	}

	.footer__logo--box{
		width: 180px;
	}

	.title{
		font-size: 1.6rem;
	}

	.all__item{
		width: 100%;
	}

	// Информация о магазине

	.shop__points--item{
		flex-direction: column;
	}

	.shop__points--img--inner{
		max-width: 100%;
		border-radius: 7px 7px 0 0;
	}

	.shop__points--info--inner{
		border-radius: 0 0 7px 7px;
	}

	.shop__points--name--abs{
		display: none;
	}

	.add__reviews--box{
		width: 100%;
	}

	.shop__city--button--inner{
		justify-content: flex-start;
	}

	// Общая страница магазина

	.address__item--box{
		flex-direction: column;
		align-items: flex-start;
	}

	.address__item--value{
		margin-right: 0;
		width: 100%;
	}

	.address__item--phone--inner, .address__item--buttons{
		width: 100%;
		margin-top: 1rem;
	}

	.address__item--address--inner{
		width: 53%;
	}

	.address__box{
		margin-top: 0;
	}

	.tovar__view--button, .tovar__cart--button{
		font-size: 1.4rem;
	}

	.tovar__content--item{
		flex-direction: column;
		align-items: center;
	}

	.tovar__item--text--inner{
		margin-top: 1rem;
	}

	// 404 страница

	.error__title{
		font-size: 2.3rem;
	}

	.error__input{
		padding: .7rem 8rem .7rem 1.5rem;
		font-size: 1.8rem;
	}

	.breadcrumbs__item{
		font-size: 1.8rem;
	}

	// Каталог магазина

	.katalog__item{
		width: 100%;
		margin: 2rem 1rem 0rem 1rem;
	}

	.sale__item{
		flex-direction: column;
	}

	.shop__points--button{
		font-size: 1.3rem;
	}

	// Акции магазина

	.akcii__item{
		flex-direction: column;
		align-items: center;
	}

	.akcii__item--text--title{
		margin-top: 1rem;
	}

	.week__item{
		width: 100%;
	}

	.akcii__item--text--button{
		margin: 1rem auto 0 auto;
	}

	// Доставка

	.delivery__title{
		font-size: 2.9rem;
	}

	.delivery__subtitle{
		font-size: 2.3rem;
	}

	.delivery__item--button{
		margin: 1rem 0rem 0rem 0;
	}

	.delivery__item--date--inner{
		flex-direction: column;
		align-items: flex-start;
	}

	.target__text{
		font-size: 1.7rem;
	}

	.target__button{
		width: 150px;
	}

	.tcgorod__maps{
		height: 300px;
	}

	.target__input{
		padding-right: 16rem;
	}

	// Отзывы

	.reviews__text--img--inner{
		width: 100%;
	}

	.revievs__content{
		flex-direction: column;
		align-items: center;
	}

	// Товар

	.tovar__img--inner{
		width: 100%;
	}

	.tovar__name--title{
		font-size: 1.9rem;
	}

	.input, #comment, #author{
		font-size: 1.4rem;
	}

	.modal__city{
		right: -49px;
		width: 300px;
	}

	.modal__city--names--inner{
		flex-direction: column;
		align-items: flex-start;
	}

	.modal__city--names--item{
		margin-right: 0;
		width: 100%;
	}

	.shop__city--name{
		width: 100%;
	}

	// Главная тц

	.tc__item{
		width: 100%;
	}

	.shop__box{
		justify-content: center;
	}

	.shop__tovar--item{
		width: 100%;
	}

	.shoping__item{
		width: 100%;
	}

	.tcmagazini__item{
		flex-direction: column;
		align-items: center;
	}

	.shoping__item--img--inner--tc{
		margin-right: 0;
	}

	.shoping__item--img--inner--tc:before{
		width: 0;
	}

	.shoping__tc--item--box{
		width: 100%;
	}

	.tc__buttons{
		flex-direction: column;
		align-items: flex-start;
	}

	.tc__shop--button{
		margin: 1rem 0rem 1rem 0;
	}

	.tc__mappage{
		height: 250px;
	}

	.tag__item{
		width: 100%;
	}

	// Корзина

	.cart__tovar--item{
		flex-direction: column;
		align-items: center;
	}

	.cart__tovar--img--inner{
		width: 100%;
		padding: 1rem 2rem;
		border-radius: 14px 14px 0 0;
	}

	.cart__tovar--content--inner{
		width: 100%;
		border-radius: 0 0 14px 14px;
		padding-top: 4rem;
	}

	// Поиск

	.search__item{
		flex-direction: column;
		align-items: center;
	}

	.search__item--img--inner, .search__item--text--inner{
		width: 100%;
		border-right: 0;
	}

	.compolya{
		max-width: 100%;
	}

	.view__item{
		width: 100%;
	}

	.reviews__title--inner{
		flex-direction: column;
		align-items: flex-start;
	}

	.revievs_hh{
		margin: 1rem 0 0 0;
	}

	#activ{
		flex-direction: column;
	}

	.tc__buttons button{
		width: 100%;
	}

	.online__content--text{
		font-size: 1.8rem;
	}

	.online__content--subtext{
		font-size: 1.6rem;
	}

	.online__feedback--item--top{
		flex-direction: column;
		align-items: center;
	}

	.online__feedback--item--social--box{
		margin-right: 0;
		flex-direction: row;
	}

	.online__feedback--item--social:last-child{
		margin: 0rem 0rem 0rem 1rem;
	}

	.online__feedback--item--text{
		margin-top: 1rem;
		margin-right: 0;
	}

	.online__feedback--item--input{
		max-width: 100%;
		margin-right: 0;
		margin-top: 1rem;
	}

	.online__feedback--item--button{
		margin-top: 1rem;
		width: 100%;
	}

	#timer-element-22d3d00acda1eb98a003baf64dc35836-secundes{
		display: none!important;
	}

	#timer22d3d00acda1eb98a003baf64dc35836{
		min-width: 100%!important;
		max-width: 100%!important;
	}

	span.timer-element{
		margin-right: 1rem!important;
	}

	#timer-element-22d3d00acda1eb98a003baf64dc35836-minutes{
		margin-right: 0rem!important;
	}

	.timer-separator{
		display: none!important;
	}

	.online__year{
		font-size: 2.4rem;
	}

	.compolya .spamblock .g-recaptcha{
		transform: scale(.8)!important;
		position: relative;
		left: 32px;
	}

	.carousel__item__tc{
		flex-direction: column;
		align-items: flex-start;
	}

	.carousel__item__tc .carousel__item--left{
		width: 100%;
		border-radius: 8px 8px 0 0;
	}

	.carousel__item-tc--img{
		border-radius: 8px 8px 0 0;
	}

	.carousel__item__tc .slick-slide img{
		border-radius: 8px 8px 0 0;
	}
}